export const medicalAidResults = [
  {
    "scheme": "Aeci",
    "plan": "Comprehensive / select",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "aecisociety@medscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 002 103"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "yes",
    "dentures": "yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "yes",
    "exclusions": "",
    "preAuth": "R13 200 per beneficiary, limited to R26 400 per family - advanced dentistry.",
    "crucialRules": "Basic dentistry No limit.",
    "notes": "No Limit Conservative and restorative dentistry, plastic dentures including technician fees",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "AECI Medical",
    "plan": "Value Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Denture Benefit Pre-authorisation required.",
    "crucialRules": "One set of acrylic or plastic dentures per beneficiary every 24 months.",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of paymen",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity",
    "plan": "CHROME, BRONZE, SILVER MAX, DELTA MAX ",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "NO Panorex and sadvanced dental.",
    "preAuth": " 3 Restorations per year. More than 3 restorations require pre-authorisation. No cover for anterior restorations. 1 Restoration code per tooth number in a 9-month period. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "1 Full mouth examination per member per annum from the date of service. 8107 / 8112 x-ray Only 2 per member per annum. Preventative Codes 8155 and 8159 Once per annum from date of the service. 8159 Prophylaxis complete dentition (older than 12 years). Limited to 3 extractions per member per annum. Maximum of 1 X 8201 per quadrant per visit.",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. 4. Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Silver",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "NO Panorex and advanced dental.",
    "preAuth": "Limited to 3 restorations per member per annum. \u2022 1 Restoration code per tooth number in a 12-month period. \u2022 Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline.",
    "crucialRules": " 8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. Only 8155 Polish Once per 6 months per member from date of service and 8159 (older than 12 years) Intra Oral Radiographs 8107 / 8112 2 x-rays per member per annum. 8104 Examination or consultation for a specific problem, \u2022 Unlimited according to protocol. Not within 4 weeks of 8101, 8104 . Limited to 3 extractions per member per annum \u2022 Maximum of 1 X 8201 per quadrant per visit ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered.  4, Restorations cannot be claimed on the same tooth number and service date as tariff 8132 , only adequatly motivated cases will be considered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Gold",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. No panorex.",
    "preAuth": "Limited to 4 restorations per member per annum. \u2022 Pre-authorisation required for more than 3 restorations per visit. \u2022 1 Restoration code per tooth number in a 12-month period. \u2022 Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. Limited to 1 RCT per member per annum. Impaction 8941 Surgical removal of impacted tooth \u2013 first tooth Limited to 2 extractions per member per annum. \u2022 Limited to Wisdom teeth only (teeth 18, 28, 38, 48) 8943 Surgical removal of impacted tooth \u2013 second tooth 8945 Surgical removal of impacted tooth \u2013 third and subsequent teeth. 1 Crown per member per annum",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 Examination or consultation for a specific problem, \u2022 Unlimited according to protocol. \u2022 Not within 4 weeks of 8101, 8104. Limited to 5 extractions permember per annum. \u2022 Maximum of 1 X 8201 per quadrant per visit ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered.  4, Restorations cannot be claimed on the same tooth number and service date as tariff 8132 , only adequatly motivated cases will be considered. Advanced Dentistry \u2013 R1 250 per policy per annum. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Platinum",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": ". No panorex. No dentures",
    "preAuth": "Limited to 5 restorations per member per annum. \u2022 Pre-authorisation required for more than 3 restorations per visit. \u2022 1 Restoration code per tooth number in a 12-month period. \u2022 Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. Impaction 8941 Surgical removal of impacted tooth \u2013 first tooth, 8943 Surgical removal of impacted tooth \u2013 second tooth 8945 Surgical removal of impacted tooth \u2013 third and subsequent teeth Limited to 3 extractions per member per annum. 2 Crowns per member per annum with a maximum of R3 500 per crown.",
    "crucialRules": "8101 Full mouth examination,Once per 6 months per member from date of service. 8104 Examination for a specific problem Unlimited according to protocol. \u2022 Not within 4 weeks of 8101, 8104. Limited to 7 extractions per member per annum. \u2022 Maximum of 1 X 8201 per quadrant per visit. 8159 & 8155 Once per 6 months per member from date of service. Limited to 2 RCT per member per annum.",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed.  ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Platinum Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes ",
    "dentures": "Yes ",
    "crownsOrBridges": "Yes ",
    "implants": "No",
    "surgicalExtractions": "Yes ",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. No panorex",
    "preAuth": "Maximum of 5 restorations per annum. \u2022 Pre-auth for more than 3 restorations. 1 Restoration code per tooth number in a 12- month period. Pre-auth for any 3 or 4 surface restorations on wisdom teeth. Root Canal: Maximum of 2 teeth per annum. Benefit include Emergency Root Canal. All specialised dentistry must be pre-authorised. Impaction: Maximum of 3 extractions per annum Wisdom teeth only (tooth 18, 28, 38, 48). . Crown 2 Crowns per member per annum with a maximum of R4000.00 per crown. . Dentures: One set of Partial Plastic Dentures every 48 months per covered member. Maximum or R4000.00. Rebase, Reline, Repair of denture, Add tooth to existing partial denture, Impression to repair denture, Once per calendar annum per member ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. Only 8159 & 8155 Once per 6 months. 8104 Examination Unlimited according to protocol. \u2022 Not within 4 weeks of 8101, 8104. Limited to 7 extraction per member per annum. \u2022 Maximum of 1 X 8201 per quadrant per visit. ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed.  ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Health",
    "plan": "COMBINED, DAY TO DAY, GOLD MAX, PLATINUM MAX AND TITANIUM MAX",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": ". No panorex x-rays. Benefits for amalgam restorations to be replaced with composite are only available where such treatment is necessary to restore secondary caries.. No specialised dentistry.",
    "preAuth": "More than 3 restorations requires pre-authorisation. Surgical Removals: Surgical removals are only covered when authorised. Extraction: Maximum of 1 X 8201 per quadrant per visit. 4 Per year more than 4 require authorisation. 3 Restorations per year. More than 3 restorations require pre-authorisation. No cover for anterior restorations. 1 Restoration code per tooth number in a 9-month period. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "1. No Specialised dentistry applies: Patient to consent to such - form to be completed and signed by patient 2. Limit of 3 fillings per annum. No cover for anterior restorations. ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. 4. Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Affinity Reef",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex x-rays. No advanced, implants, crown& bridges or theatre and concious sedation.",
    "preAuth": " 3 Restorations per year. More than 3 restorations require pre-authorisation. No cover for anterior restorations. 1 Restoration code per tooth number in a 9-month period. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "1 Full mouth examination per member per annum from the date of service. Diagnostic Codes 8107 Periapical x-ray & 8112 Bitewing x-ray Only 2 per member per annum. 8159 & 8155 Once per annum from date of the service. Limited to 3 extractions per member per annum. Maximum of 1 X 8201 per quadrant per visit. ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. 4. Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed. 5. Dental Limits R1 500 per member per year",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Elevate",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "086 111 0033 / 087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " No panorex x-rays. No advanced, implants, crown& bridges or theatre and concious sedation.",
    "preAuth": "Limited to 3 restorations per member per covered period. \u2022 1 Restoration code per tooth number in a 9-month period. \u2022 Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 8937 Surgical removal of tooth Surgical removal is only covered when authorised. 8935 Treatment of septic socket More than 1 treatment per visit requires authorisation. ",
    "crucialRules": "One full mouth examination per member per covered period. \u2022 180 days apart from date of service. Only 1 Scale and polish per member per covered period 180 days apart from date of service days apart. Limited to 3 extractions per member per covered period. \u2022 Maximum of 1 X 8201 per quadrant per visit ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Vital",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1286"
      ],
      "fax": [
        " 086 687 1286"
      ],
      "phone": [
        "87 111 0033 / 087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " No panorex x-rays. No advanced, implants, crown& bridges or theatre and concious sedation.",
    "preAuth": "Limited to 3 restorations per member per covered period. \u2022 1 Restoration code per tooth number in a 9-month period. \u2022 Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 8937 Surgical removal of tooth Surgical removal is only covered when authorised. 8935 Treatment of septic socket More than 1 treatment per visit requires authorisation.",
    "crucialRules": "One full mouth examination per member per covered period. \u2022 180 days apart from date of service. 1 Scale and polish per member per covered period \u2022 180 days apart from date of service days apart. Limited to 3 extractions per member per covered period. \u2022 Maximum of 1 X 8201 per quadrant per visit ",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider. 3. Patient written consent needed for procedures not covered. 4",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "AFRICAN UNITY (AUL)",
    "plan": "NFS-1 LITE NFS-1 PREMIUM NFS-1 PRESTIGE",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "0861707070",
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. Pre-auth: \u2022 More than 4 restorations per annum. \u2022 Any 3 or 4 surface restorations on wisdom teeth. 1 Restoration code per tooth number in a 9-month period. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 Examination Not within 4 weeks of 8101, 8104. 8107 / 8112 2 per member per consultation not more than 4 per annum. 8159,8155,8161,8162 Once every 6 months per member from date of service. ",
    "notes": ". Benefits payable on the EEB Essential Plan is subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "AGS",
    "plan": "PRIME OPTION, GREEN & GREEN COMBO OPTIONS",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Bite Plate NO BENEFITS Mouth Guard NO BENEFITS Root Canal NO BENEFITS Temporary Crown NO BENEFITS Specialised Dentistry Benefits NO BENEFITS",
    "preAuth": "Limited of 2 restorations per member per annum. \u2022 1 Restoration code per tooth number in a 9-month period. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline.",
    "crucialRules": "3 Consultations per member per annum \u2022 One 8101 per 6 months per member from date of service. \u2022 8104 - Not within 4 weeks of code 8101. 2 x-rays per member per annum. Emergency Root Canal Maximum of 2 per annum. Limited to 2 extractions per member per annum. \u2022 Maximum of 1 X 8201 per quadrant per visit. ",
    "notes": ". 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "AGS",
    "plan": "Bronze",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Impaction in the Chair NO BENEFITS Impaction in Hospital NO BENEFITS Crown NO BENEFITS",
    "preAuth": "8410 Provisional crown Pre-authorisation and x rays required. 1 per member per annum. Dentures One full set of plastic dentures per member every 5 years. \u2022 Maximum or R3 665 ",
    "crucialRules": " 3 Consultations per annum. 8101 - Once per 6 months per member . 8104 - Not within 4 weeks of code 8101. Emergency Root Canal. Maximum of 2 per year. . 8159 & 8151 per member per annum. 3 x-rays per member per annum. 8115 Panoramic x-ray Once per lifetime. Emergency root canal treatment Maximum of 2 per annum. Limited to 3 restorations per member per annum. \u2022 1 Restoration code per tooth number in a 9month period. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 8169 Occlusal guard 1 Bite plate per member per annum. 8171 Mouth guard (members younger than 23) 1 Mouth guard per member per annum.",
    "notes": "Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "AGS",
    "plan": "SILVER OPTION, BLUE, BLUE COMBO, RED & RED COMBO OPTION",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated. No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated. No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and preauthorised unless adequately motivated. No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated. High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "1 Bite plate and 1 mouth guard per member per annum. Root Canal Pre-auth. Maximum of 2 root canals per year. Temporary Crown Pre-auth. Maximum of 2 temporary crowns per year. 1 Crown per member. Dentures Pre-auth. One full set of plastic dentures . Pre-auth. Implants (Pin and Placement) (Including Components) All specialised dentistry must be pre-authorised and are paid according to the stated benefit. 8410 Provisional crown Pre-auth Maximum of 2 temporary crowns per annum. Impaction in the Chair pre-auth Limited to 4 extractions per member per annum. \u2022 Limited to Wisdom teeth only (teeth 18, 28, 38, 48). Impaction in Hospital Limited to 2 extractions per member per annum. \u2022 Limited to Wisdom teeth only .One full set of plastic dentures per member. 1 Implant per member per annum up to R6 105 per implant. ",
    "crucialRules": "4 Consultations per member per annum. \u2022 One 8101 per 6 months per member from date of service. \u2022 8104 - Not within 4 weeks of code 8101. Scale and Polish 8159 & 8155 1 per member per annum. 5 x-rays per member per annum. Emergency Root Canal Maximum of 3 per annum. Limited to 3 extractions per member per annum. \u2022 Maximum of 1 X 8201 per quadrant per visit. Limited to 4 restorations per member per annum. \u2022 1 Restoration code per tooth number in a 9 month period. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline.",
    "notes": "Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "AGS",
    "plan": "Gold",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated. No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated. No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and preauthorised unless adequately motivated. No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated. High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Root Canal Pre-auth. Temporary Crown pre-auth. Impaction in the Chair . Applicable to Wisdom teeth only (tooth 18, 28, 38, 48). Impaction in Hospital Maximum of 4 extractions per year. Applicable to Wisdom teeth only (tooth 18, 28, 38, 48). Crown . 2 Crowns per member up to R4 885 per crown . Provisional crown preauth. Maximum of 3 teeth per year. One full set of plastic dentures per member every 5 years. Maximum or R4275. 2 Implants per member up to R7325 per implant. Member cannot join and receive cover for a previously extracted tooth.Implants (Pin and Placement) (Including Components) .",
    "crucialRules": " 5 Consultations per annum. One 8101 per 6 months per member. 8104 - Not within 4 weeks of code 8101. Panoramic x-ray Once per lifetime. Emergency Root Canal Maximum of 3 per year. Maximum of 4 restorations per year. 1 Restoration code per tooth number in a 9-month period. Occlusal guard 1 per member per annum. Mouth guard (members younger than 23) 1 per member per annum. ",
    "notes": "Patient to consent to such - form to be completed and signed by patient. 1. Very basic option.Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Alliance midmed",
    "plan": "Alliance midmed",
    "administrator": "Alliance-midmed",
    "contactInfo": {
      "email": [
        "auths@alliancemidmed.co.za service@alliancemidmed.co.za"
      ],
      "fax": [],
      "phone": [
        " 0860 00 2101"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "- Implants No benefit",
    "preAuth": "Root Canal Therapy and Extractions Limited to benefit confirmation. Plastic Dentures Limited to One set (upper and lower). Per beneficiary in a 4-year period. Partial Metal Frame Denture Pre-auth required.. Limited to two partial metal frames (an upper and a lower).- Crown and Bridge Per Family benefit. Pre-auth required. Limited to 3 crowns per beneficiary. Dentistry - Anaesthetic - General Limited to children under 7 years ",
    "crucialRules": "Fillings Limited to once per tooth in 720 days. ",
    "notes": "Fillings Limited to once per tooth in 720 days. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Anglo Medical aid",
    "plan": "Managed Care",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "member@angloms.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 222 633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "",
    "preAuth": "Specialised treatments including crowns, bridges, inlays, study models, dentures, orthodontics, osseo-integrated implants or similar tooth implants and periodontics.",
    "crucialRules": "Dental hospitalisation : authorisation before undergoing dental treatments in hospital: in the case of trauma if patients are under the age of 7 years requiring anaesthetic,for the removal of impacted molars or maxillo-facial and oral surgery (PMB conditions). Authorised specialist services in hospital are paid at the Top-Up rate of 230% of SRR, or in full if PMB.",
    "notes": "Dental hospitalisation : authorisation before undergoing dental treatments in hospital: in the case of trauma if patients are under the age of 7 years requiring anaesthetic,for the removal of impacted molars or maxillo-facial and oral surgery (PMB conditions). Authorised specialist services in hospital are paid at the Top-Up rate of 230% of SRR, or in full if PMB.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Anglo Medical aid",
    "plan": "Standard",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. RCT on posterior teeth limited to members 12 years and older. Retreatment subject to preauthorisation and managed care protocols. No cover for third molars (8\u2019s). No cover for primary teeth, No Ortho. No Crowns and Bridges.",
    "preAuth": "\u2022 Maximum 1 x 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. More than 5 restorations per year \u2022 Two or more: 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 Multiple restorations on an anterior tooth on the same service date. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. \u2022 Deviations will only be covered if pre-authorised. \u2022 Any 3 or 4 surface restorations on wisdom teeth. \u2022 1 Restoration code per tooth number in a 9-month period. RCT on posterior teeth limited to members 12 years and older. Denture Once every 4 years per member ",
    "crucialRules": "8101 Once per 6 months per member from date of service. 8104 Not within 4 weeks of 8101 .Motivation required for code 8104 if no covered clinical procedure is charged.Maximum of 4 x-rays per visit per member. (Overall max of 7 per annum). 8155 One per member in a 3-year period from date of service. 8155, 8159 Once per 6 months per member from date of service. ",
    "notes": "). Sterilised and wrapped instrumentation Will only be paid if code 9011, 9013, 8731, 8201 or 8202 is claimed. Emergency root canal treatment, Not covered on primary teeth.The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Denture Codes (Detailed codes required) Once every 4 years per member. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Anglo Medical aid",
    "plan": "Value",
    "administrator": "Primecure",
    "contactInfo": {
      "email": [
        "support@primecure.co.za"
      ],
      "fax": [],
      "phone": [
        "0861665665"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic and other extra oral x-rays (e.g., 8115) does not form part of the benefit schedule. ",
    "preAuth": "8935 Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202). Extractions - Pre-Authorisation is needed for four or more extractions per beneficiary per year. One (1) surgical extraction per beneficiary per year. Must be authorised . 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth. 8162 needs to be authorised. Fillings - White/Resin (anterior only) or Amalgam posterior - Note: Posterior Resin fillings will be paid for at Amalgam rates. Denture are limited to pre-authorisation. Dentures, reline, rebase and all specialised dentistry - Partial dentures. Conscious sedation pre auth. Inhalation sedation: under 7 years of age.",
    "crucialRules": "8101 Consultation oral examination Once per year, 8104 Examination for a specific problem not requiring full mouth examination. 8107, 8112 Intra oral radiographs/Bite Wing X-rays, per film (maximum of four X-rays per family per year). 8155 Polishing only Once a year for ages 3-12yrs.8159 Scaling and polishing Once a year over the age 12 yrs 8161 Topical application of fluoride Once a year for ages 3 to 12 yrs. 8132 Gross pulpal debridement, primary & permanent teeth (emergency root canal). Pre-authorisation if a patient neesd repairs to four or more teeth (including fillings) per person per year. One (1) consultations/ examinations code 8101 per beneficiary per year.",
    "notes": "8201, 8202 Extraction, single tooth. Code 8201 is charged for the first extraction in a quadrant. Maximum for 8201 & 8202 is three (3), thereafter pre-authorisation required for the first extraction in a quad-rant. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bankmed",
    "plan": "Basic",
    "administrator": "Discovery / DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1285"
      ],
      "fax": [
        " it to 086 687 1285"
      ],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex or specialised dentistry.All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required) 1. No Specialised dentistry applies No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth. No Benefit for Crowns",
    "preAuth": ". 8104 Examination Not within 4 weeks of 8101, 8102, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). Maximum 1 x 8201 per quadrant per visit. 8202 each additional tooth in the same quadrant \u2022 More than 4 extractions per visit pre-auth.8132 Maximum of 1 per treatment date. \u2022 Once per tooth number per lifetime. \u2022 The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. More than 4 restorations per year. \u2022 Two or more 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 Multiple restorations on an anterior tooth on the same service date. DENTURES Once every 24 months",
    "crucialRules": " 8101 Full mouth examination, Once per 6 months per member from date of service. 8107 /8112 Maximum of 2 x-rays per visit per member. \u2022 Maximum quantity of 7 per year. 8159 ,8155,8161,8162 Once per 6 months per member from date of service. ",
    "notes": "No Panorex. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. Should there be no authorisation obtained where indicated in limitations \u2013 no benefit. Provider to write off account. Only codes stated below with limitations are covered should a code not be in the manual then it is not covered. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bankmed",
    "plan": "Traditional",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No metal base partial dentures",
    "preAuth": "Authorisation all Advanced Dentistry",
    "crucialRules": "Combined limit for advanced dentistry, orthodontics and all other dental services. Limited to eight molar teeth pb per lifetime. ",
    "notes": "Plastic dentures pays from basic dentistry. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bankmed",
    "plan": "Core saver",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Authorisation all Advanced Dentistry",
    "crucialRules": "Combined limit for advanced dentistry, orthodontics and all other dental services. Limited to eight molar teeth pb per lifetime. ",
    "notes": "Subject to available Medical Savings Account",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bankmed",
    "plan": "Comprehensive",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Advanced Dentistry Caps, crowns, bridges and cost of endosteal and osseaintegrated implants",
    "crucialRules": "Combined limit for advanced dentistry, orthodontics and all other dental services. Limited to eight molar teeth pb per lifetime. ",
    "notes": "Unlimited; paid from Insured Benefit",
    "source": null,
    "lastUpdated": "Jan-25 "
  },
  {
    "scheme": "Bankmed",
    "plan": "Plus Plan",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Advanced Dentistry Caps, crowns, bridges and cost of endosteal and osseaintegrated implants",
    "crucialRules": "Combined limit for advanced dentistry, orthodontics and all other dental services. Limited to eight molar teeth pb per lifetime. ",
    "notes": " subject to available Medical Savings Account",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Barloworld",
    "plan": "Barloworld",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "bcima@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860002106"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Authorisation all Advanced Dentistry. Orthodontic Treatment is included in the combined benefit.",
    "crucialRules": " Benefit for retreatment of a tooth is subject to clinical protocols. ",
    "notes": "The combined benefit incorporates more advanced dental procedures, including treatment for inlays, crowns, root canal therapy, bridges, mounted study models, dentures and the treatment by periodontists, prosthodontists and dental technician's fees for all such dentistry. The benefit includes Oral medical procedures and metal base to complete denture code 8663.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "BCIMAS",
    "plan": "Plus",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "bcima@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0112081005"
      ]
    },
    "networked": "no",
    "nonNetworkedPayment": "no",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Authorisation all Advanced Dentistry. Orthodontic Treatment is included in the combined benefit.",
    "crucialRules": "Conservative: Subject and limited to available PMSA. Specialised Dentistry: Subject and limited to available PMSA. Orthodontic treatment: Subject and limited to available PMSA.",
    "notes": " Very basic option ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "BCIMAS",
    "plan": "Basic",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "bcima@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0112081005"
      ]
    },
    "networked": "no",
    "nonNetworkedPayment": "no",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Authorisation all Advanced Dentistry Specialised: crowns, bridgework, orthodontics, periodontics, prosthodontics, plastic dentures, maxillo-facial, oral surgery, etc. . Orthodontic Treatment is included in the combined benefit. \u2022 Dentistry (in-hospital procedures, subject to pre-authorisation) ",
    "crucialRules": "Preventative Care Benefit - Unlimited and will not affect your Hospital and Day-to-Day Limits. \u2022 Conservative Dentistry \u2022 For beneficiaries under 6 years of age",
    "notes": " Very basic option ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat 2 HOSPITAL PLAN (WITH SAVINGS)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Specialised dentistry Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation,",
    "crucialRules": "Basic: Preventative benefit or savings account. Specialised: Savings account. Orthodontic: Subject to pre-authorisation",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat 3 HOSPITAL PLAN (WITH SAVINGS AND SELECTED RISK BENEFITS)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Surgical extractions of teeth / roots / impactions / failed implants; - Surgical drainage of dental abscess;",
    "preAuth": "Specialised dentistry Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation. Major medical maxillo-facial surgery strictly related to certain conditions. Limited to R14 256 per family. Dental and oral surgery (In- or out of hospital). Limited to R8 893 per family.",
    "crucialRules": "Basic: Preventative benefit or savings account. Specialised: Savings account. Orthodontic: Subject to pre-authorisation.",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat 4 Savings account/Day-to-day benefits",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Specialised dentistry Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation. Dental and oral surgery (In- or out of hospital) Limited to R11 117 per family. ",
    "crucialRules": "Basic: Preventative benefit or savings account. Specialised: Savings account. Orthodontic: Subject to pre-authorisation. Basic and specialised dentistry Savings and then from day-to-day limit. Orthodontics are subject to preauthorisation. Limited to M = R6 223, M1+ = R12 499. (Subject to overall day-to-day limit)",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace1 ",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Specialised dentistry. Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation, ",
    "crucialRules": "Basic and specialised dentistry Savings and then from day-to-day limits. Dental and oral surgery (In- or out of hospital) Limited to R8 893 per family. Basic and specialised dentistry Savings and then from day-to-day limits. Orthodontic: Subject to preauthorisation. Limited to M = R4 550, M1+ = R9 234. (Subject to overall day-to-day limit)",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace2 COMPREHENSIVE COVER",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Specialised dentistry Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation. Orthodontic dentistry Savings first. 100% Scheme tariff. Subject to pre-authorisation. Limited to R7 399 per event for beneficiaries up to 18 years of age. Subject to overall day-to-day limit.",
    "crucialRules": "Basic and specialised dentistry Savings and then from day-to-day limits. Dental and oral surgery (In- or out of hospital) Limited to R14 779 per family. Basic and specialised dentistry Savings and then from day-to-day limit. Limited to M = R7 628, M1+ = R15 256. (Subject to overall day-to-day limit)",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace3",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Specialised dentistry Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation. Orthodontic dentistry Savings first. 100% Scheme tariff. Subject to pre-authorisation. Limited to R9 513 per event for beneficiaries up to 18 years of age. Subject to overall dayto-day limit.",
    "crucialRules": "Basic and specialised dentistry Savings first and then from day-to-day limit. Limited to M = R8 219, M1+ = R15 323. (Subject to overall day-to-day limit).",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace4",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Specialised dentistry Subject to pre-authorisation. Preventative dentistry may be subject to pre-authorisation. Major medical maxillo-facial surgery strictly related to certain conditions 100% Scheme tariff. Dental and oral surgery (In- or out-of-hospital) Limited to R22 233 per family.",
    "crucialRules": "Basic and specialised dentistry Limited to M = R13 717, M1+ = R23 152. (Subject to overall day-to-day limit) Orthodontic dentistry 100% Scheme tariff. Subject to pre-authorisation. Limited to R11 627 per event for beneficiaries up to 18 years of age. (Subject to overall day-to-day limit)",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Rhythm 2 (Pulse)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "YES",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "Dental and oral surgery (In- or out of hospital) No benefit. Crowns and other specialised dentistry are not covered.",
    "preAuth": "dentures Once per jaw every 24 months per family.",
    "crucialRules": "81 two 2 consultation for full mouth examination per beneficiary per year. 8104 Maximum of 3 per year, not within 4 weeks after 8101. Maximum of two films (8107 x2 or 8112 x2 or combination of 8107 & 8112) every 6 months. 8159 : Two (2) per beneficiary of 12 years and older. Once every 6 months. 8155 : Two (2) per beneficiary younger than 12 years. Once every 6 months. 8161 Two (2) per beneficiary younger than 12 years. Once every 6 months. 8162 : Two (2) per beneficiary of 12 years and older. Once every 6 months. 8201 Basic extractions if clinically necessary (1 per quadrant per beneficiary per year). Restorations are limited to 6 restoration codes per beneficiary. ",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bestmed",
    "plan": "Rhythm 1 (Pulse)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002379"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "YES",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "Dental and oral surgery (In- or out of hospital) No benefit. Crowns and other specialised dentistry are not covered.",
    "preAuth": "dentures Once per jaw every 24 months per family.",
    "crucialRules": "8101 1 (one) consultation for full mouth examination per beneficiary per year. 8104 Maximum of 3 per year, not within 4 weeks after 8101. Maximum of two films (8107 x2 or 8112 x2 or combination of 8107 & 8112) every 6 months. 8159 : Once per year per beneficiary of 12 years and older. 8155 : Once per year per beneficiary of 12 years and older. 8161 Once per year per beneficiary younger than 12 years. 8162 Once per year per beneficiary of 12 years and older. 8201 Basic extractions if clinically necessary (1 per quadrant per beneficiary per year). Restorations are limited to 6 restoration codes per beneficiary. ",
    "notes": "Space maintainers During primary and mixed denture stage. Panorex Once every 36 months. Intra-oral radiograph 2 x photos per year. Scaling and/or polishing. Twice a year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "BMW Employees M",
    "plan": "",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860 445566"
      ],
      "fax": [],
      "phone": [
        " 0860 002 107"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No specific exclusions",
    "preAuth": "Pre Authorised all Advanced Dentistry. Reference Number.",
    "crucialRules": "Dental appliances include crowns, dentures, bridges, clasps, veneers, implants, inlays or onlays and pontics. Professional fees, laboratory fees and the cost of the components used in placing dental appliances add up to the limit and are subject to your day-to-day limit.",
    "notes": "Basic (conservative) dentistry including minor oral surgery Cover is subject to the overall annual limit (OAL) and thereafter paid from day-to day benefits. Cover includes removal of teeth and roots, exposure of teeth for orthodontic reasons and suturing of traumatic wounds. Specialised dentistry Cover is subject to the overall annual limit (OAL) and includes services for inlays, crowns, bridges, mounted study models, metal base partial dentures.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "BONCOMPREHENSIVE",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "fax: 0866 770 336 dentures tel : 0861 033 647 email: customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " \u2022 Crowns on third molars \u2022 Crown and bridge procedures for cosmetic reasons and associated laboratory costs \u2022 Provisional crowns and associated laboratory costs \u2022 Porcelain veneers and inlays, and associated laboratory costs. The hospital and anaesthetist claims for the procedures listed below will not be covered when performed under general anaesthesia. The payment of the dental procedure will be dependent on available benefits, and payable at the BDT:\u2022 Apicectomies, Dentectomies Frenectomies Implantology and associated surgical procedures, Conservative dental treatment (fillings, extractions and root canal therapy) for adults \u2022 Professional oral hygiene procedures Surgical tooth exposures for orthodontic reasons",
    "preAuth": "Fillings Granted once per tooth in 720 days. A treatment plan and X-rays required for multiple fillings. Plastic DenturesPre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period .Crown & Bridge Pre-authorisation required Three crowns per family per year. Implants Pre-authorisation required Two implants per beneficiary in a 5-year period. Hospitalisation (General Anaesthetic)*Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment General anaesthetic benefit available for the removal of impacted teeth . Sedation in the Dental Rooms Pre-authorisation required.",
    "crucialRules": "Consultations Two check-ups per beneficiary per year (once every 6 months). X-rays: Extraoral One per beneficiary in a 3-year period. X-rays: Intraoral Benefit subject to managed care protocols.8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age. Root Canal Therapy and Extractions Benefit subject to managed care protocols",
    "notes": "Benefit for orthodontic treatment granted once per beneficiary per lifetime. Patient written Accountability form needed for procedures not covered. Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "Bonclassic",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za fax: 0866 770 337"
      ],
      "fax": [],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "no",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants* and Associated Laboratory Costs No benefit",
    "preAuth": "Fillings Granted once per tooth in 720 days. A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation, One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial metal frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge. Pre-authorisation required One crown per family per year . Pre-authorisation required General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment and for the removal of impacted teeth. Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "STANDARD, STANDARD SELECT, BONCOMPLETE",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 337"
      ],
      "fax": [],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration. The polishing of restorations. Gold foil restorations. Root canal therapy on primary (milk) teeth. Root canal therapy on third molars (wisdom teeth). Direct and indirect pulp capping procedures. Provisional dentures and associated laboratory costs. The metal base to full dentures and associated laboratory costs. Laboratory delivery fees. Crowns on third molars. Provisional crowns Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no preauthorisation required). Implants: no benefits.",
    "preAuth": "Fillings Granted once per tooth in 720 days. A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation, One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required one partial metal frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge. Pre-authorisation required One crown per family per year . Pre-authorisation required General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment and for the removal of impacted teeth. Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "PRIMARY & PRIMARY SELECT",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 340"
      ],
      "fax": [],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No crowns, bridges, implants, ortho. No Specialised dentistry. No Metal Plate dentures. ",
    "preAuth": "Fillings Granted once per tooth in 720 days. A treatment plan and X-rays required for multiple fillings. Plastic DenturesPre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Hospitalisation (General Anaesthetic) No benefit for In Hospital (General Anaesthetic) dentistry, except for PMBs. Subject to Primary Select Hospital Network Subject to pre-authorisation . Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment Benefit subject to managed care protocols .",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Root Canal Therapy and Extractions Benefit subject to managed care protocols.",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "Bonsave",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 342"
      ],
      "fax": [],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "no",
    "surgicalExtractions": "Yes",
    "exclusions": "No benfits for Implants",
    "preAuth": " Procedures and treatment not pre-authorised will not attract a benefit. . Plastic DenturesPre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial metal frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge. Pre-authorisation required 3 crowns per family per year . Hospitalisation (General Anaesthetic)Pre-authorisation required General anaesthetic benefit ONLY available for the removal of impacted teeth. Sedation in the Dental Rooms Pre-authorisation required For the removal of impacted teeth \u2013 payable from Risk For any other extensive dental treatment, payable from Savings ",
    "crucialRules": "8101 Two consultations per beneficiary per year (once every 6 months). 8155 Two scale and polish treatments per beneficiary per year (once every 6 months). 8159 Code 8155 and/or 8159 (max of 2 in total per year). 8161 & 8162 Two treatments per year, 8163 One per tooth in a 3-year period for beneficiaries younger than 16 years of age. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Fillings Granted once per tooth in 720 days. A treatment plan and X-rays required for multiple fillings",
    "notes": "Root Canal Therapy and Extractions Benefit for root canal therapy includes all teeth except primary teeth and permanent molars. Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "Bonfit Select",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 343"
      ],
      "fax": [],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "no",
    "surgicalExtractions": "Yes",
    "exclusions": "No benfits for Implants",
    "preAuth": "Hospitalisation (General Anaesthetic) No benefit for In-hospital (General Anaesthetic) dentistry, except for PMBs. Subject to pre-authorisation. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial metal frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge. Pre-authorisation required. Three crown per family per year . Hospitalisation (General Anaesthetic) No benefit for In Hospital (General Anaesthetic) dentistry, except for PMBs. Sedation in the Dental Rooms Pre-authorisation required Payable from Savings Benefit limited to extensive dental treatment. ",
    "crucialRules": "8101 Two consultations per beneficiary per year (once every 6 months). 8155 Two scale and polish treatments per beneficiary per year (once every 6 months). 8159 Code 8155 and/or 8159 (max of 2 in total per year). 8161 & 8162 Two treatments per year, 8163 One per tooth in a 3-year period for beneficiaries younger than 16 years of age. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Fillings Granted once per tooth in 720 days. A treatment plan and X-rays required for multiple fillings",
    "notes": "Root Canal Therapy and Extractions Benefit for root canal therapy includes all teeth except primary teeth and permanent molars. Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "Boncap",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 344"
      ],
      "fax": [],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " X-rays Extraoral \u2013 NO Benefit. This option does not provide benefits for any Specialised Dentistry. Hospitalisation (General Anaesthetic) No benefits for in hospital (general anaesthetic) dentistry, except for PMBs. Pre-authorisation required. ",
    "preAuth": "Plastic Dentures. Pre-authorisation required One set of plastic dentures (an upper and a lower) per family in a 2-year period for beneficiaries. Deep Sedation: Pre-authorisation required. 8201 Extraction of tooth or exposed roots. Benefit for 4 fillings per beneficiary per year Benefit for fillings are granted once per tooth in 720 days. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per family in a 2 year period for beneficiaries 21 years and older. 20% co-payment applies. Hospitalisation (General Anaesthetic) No benefits for in hospital (general anaesthetic) dentistry, except for PMBs. Pre-authorisation required",
    "crucialRules": "8101 One check-up per beneficiary per year (Once every 365 days). 8104 One specific consultation (emergency) for pain and sepsis per beneficiary per year. 8107 and/or 8112 Four X-rays in total per beneficiary per year . One polish (8155) or one scaling and polishing (8159) per beneficiary per year .8163 One per tooth in a 3-year period for beneficiaries younger than 16 years of age. 8161,8162 One treatment per year for beneficiaries under 16 years of age. ",
    "notes": "8259 Rebase of denture once per family per year. 8263, 8269, 8271, 8273, Repairs to existing dentures twice per family per year.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "BONESSENTIAL & BONESSENTIAL SELECT",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 345"
      ],
      "fax": [],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Consultations No benefit X-rays: Intraoral No benefit X-rays: Extraoral No benefit. Fillings No benefit Root Canal Therapy and Extractions No benefit Plastic Dentures No benefit. SPECIALISED DENTISTRY No benefit ",
    "preAuth": "Hospitalisation (General Anaesthetic) Pre-authorisation required General anaesthetic benefit available for the removal of impacted teeth ONLY. Sedation in the Dental Rooms Pre-authorisation required Benefit subject to managed care protocols.",
    "crucialRules": "Fissure sealants (tariff code 8163) are available for beneficiaries younger than 16 years of age and limited to one per tooth in 3 years",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Bonitas",
    "plan": "BONSTART & BONSTART PLUS",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "dentures tel : 0861 033 647 email: customercare@denis.co.za crowns@denis.co.za ortho@denis.co.za hospitalenq@denis.co.za fax: 0866 770 347"
      ],
      "fax": [],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Basic Dentistry. No Specialised dentistry.",
    "preAuth": "Hospitalisation (General Anaesthetic). No benefit for In-hospital (General Anaesthetic) dentistry, except for PMBs. Subject to BonStart Hospital Network Subject to pre-authorisation",
    "crucialRules": "8101 One consultation per beneficiary per year (not within 6 months from the previous year\u2019s consultation) R125 co-payment applies to the charged code 8101. 8155 & 8159 One scale and polish treatment per beneficiary per year (not within 6 months from the previous year\u2019s scale and polish). Code 8155 or 8159 (max of 1 in total per year). Fluoride Treatment One treatment per year, 8163 One per tooth in a 3-year period. ",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Alliance",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY: CROWNS, BRIDGES, ORTHODONTICS, DENTURES. limited: 80% CBT limited to: M0 R21 348 M1 R31 906 M2+ R38 478",
    "crucialRules": " limited: 80% CBT limited to: M0 R21 348 M1 R31 906 M2+ R38 478",
    "notes": " ONE DENTISTRY CONSULTATION General checkup only - excludes consumables BASIC DENTISTRY. 80% ADVANCED DENTISTRY: CROWNS, BRIDGES, ORTHODONTICS, DENTURES. 80% CBT limited ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Double Plus",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY: CROWNS, BRIDGES, ORTHODONTICS, DENTURES. 100% CBT per beneficiary. 80% CBT limited to: M0 R15 900 M1 R22 896 M2+ R30 835",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry. 80% CBT",
    "notes": "BASIC DENTISTRY. 80% ADVANCED DENTISTRY: CROWNS, BRIDGES, ORTHODONTICS, DENTURES. 80% CBT limited . 80% CBT limited to: M0 R15 000 M1 R21 600 M2+ R29 090",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Essential Plus",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures.. Subject to Medical Savings Account",
    "crucialRules": "",
    "notes": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: Basic dentistry. Subject to Medical Savings Account",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Essential Network",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures.. Subject to Medical Savings Account",
    "crucialRules": "",
    "notes": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: Basic dentistry. Subject to Medical Savings Account",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "First Choice",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures 50% CBT. Advanced Dentistry R 8 220. ",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry. 80% CBT",
    "notes": "DENTISTS Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: Basic dentistry R 3 922",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Network Choice",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY CROWNS, BRIDGES, ORTHODONTICS, DENTURES",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry. 80% CBT. ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures. Advanced Dentistry R 8 220",
    "notes": "DENTISTS. Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: Basic dentistry R 3 923",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Vital ",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY CROWNS, BRIDGES, ORTHODONTICS, DENTURES",
    "crucialRules": "ONE DENTISTRY CONSULTATION. GENERAL CHECK UP ONLY. NO DAY TO DAY BENEFIT. ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "CAMAF",
    "plan": "Vital Network",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545 or 011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "dental implants excluded",
    "preAuth": "",
    "crucialRules": "ONE DENTISTRY CONSULTATION GENERAL CHECK UP ONLY",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Compcare",
    "plan": "SELFCARE",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes. Bleaching of teeth that have not been root canal treated. High impact acrylic dentures. The cost of the use of gold in dentures. Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness, accident or disease.",
    "preAuth": "Specialised dentistry Including maxillofacial and oral surgery- in-and-out of hospital combined benefit (A quotation must be submitted for approval",
    "crucialRules": " Multiple fillings: A treatment plan and Xrays may be required for multiple fillings. Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. ",
    "notes": "Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment. No benefit for orthodontic treatment for patients older than 18 years. 1 consultation per beneficiary (PB) paid from risk.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Compcare",
    "plan": "SaverCare",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes. Bleaching of teeth that have not been root canal treated. High impact acrylic dentures. The cost of the use of gold in dentures",
    "preAuth": "Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays.",
    "crucialRules": " Multiple fillings: A treatment plan and Xrays may be required for multiple fillings. Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. ",
    "notes": "Basic dentistry Conservative and restorative. Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Subject to protocols. A quotation must be submitted for approval prior to the commencement of the treatment. No benefit for orthodontic treatment for patients older than 18 years",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Compcare",
    "plan": "ExtraCare",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes. Bleaching of teeth that have not been root canal treated. High impact acrylic dentures. The cost of the use of gold in dentures. ",
    "preAuth": "Dentures, crowns, bridgework, metal fillings and inlays. Subject to a sub-limit of R14 850 PB and R20 000 PMF. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment. No benefit for orthodontic treatment for patients older than 18 years.",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions, and infection control. Subject to PMSA. No benefit for orthodontic treatment for patients older than 18 years. Kids under 6 get unlimited visits to basic dentistry. Paid from Risk. Subject to PMSA, AFB and SPG.",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Compcare",
    "plan": "UltraCare UltraCare+",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes. Bleaching of teeth that have not been root canal treated. High impact acrylic dentures. The cost of the use of gold in dentures",
    "preAuth": " Unlimited basic dentistry per child under the age of 6 years funded from risk. Specialised dentistry Subject to PMSA and AFB. Dentures, crowns, bridgework, metal fillings and inlays. ",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions, and infection control. Subject to PMSA. No benefit for orthodontic treatment for patients older than 18 years. ",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Compcare",
    "plan": "ExecuCare | ExecuCare+",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes. Bleaching of teeth that have not been root canal treated. High impact acrylic dentures. The cost of the use of gold in dentures",
    "preAuth": "Specialised dentistry. Dentures, crowns, bridgework, metal fillings and inlays. Specialised dentistry. Dentures, crowns, bridgework, metal fillings and inlays. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment. No benefit for orthodontic treatment for patients older than 18 years.",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions, and infection control.. No benefit for orthodontic treatment for patients older than 18 years. ",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "De Beers",
    "plan": "De Beers",
    "administrator": "De Beers",
    "contactInfo": {
      "email": [
        "debeerspreauth@ppsha.co.za"
      ],
      "fax": [],
      "phone": [
        "0538073111"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: \u2022 Dental extractions for non-medical purposes. \u2022 Bleaching of teeth that have not been root canal treated. \u2022 High impact acrylic dentures. \u2022 The cost of the use of gold in dentures",
    "preAuth": "Theatre Children under the age of nine (limited to one admission per year); and -Removal of impacted wisdom teeth PRE-AUTHORISATION REQUIRED ONLY IF DONE IN HOSPITAL. Crowns, dentures, bridges and periodontal treatment pre auth.",
    "crucialRules": "1 oral examination, scale and polish every 6 months, from date of last service. \u2022 Includes preventative and diagnostic consultations, fillings, extractions, cleanings, and X-rays.",
    "notes": "Conservative dentistry:includes preventative and diagnostic consultations, fillings, extractions, cleanings and x-rays. pre-authorisation is required in respect of Elective procedures where general anaesthesia is required. Children under the age of nine (limited to one admission per year) .Removal of impacted wisdom teeth, apicectomies, removal of teeth and roots or exposure of teeth for orthodontic reasons. No limit applies in respect of dentistry required as a result of trauma.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "De Beers",
    "plan": "Saver Series: Classic | Classic Delta | Essential | Essential Delta | Coastal Priority series: Classic | Essential",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "'0860445566"
      ],
      "fax": [],
      "phone": [
        "0860 445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "yes",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Dental limit: There is no overall limit for basic dental treatment. However, all dental appliances and prostheses, their placement, and orthodontic treatment (including related accounts for orthognathic surgery) are paid at 100% of the Discovery Health Rate (DHR). We pay these claims from your day-to-day benefits. Severe dental and oral surgery in hospital The Severe Dental and Oral Surgery Benefit covers a defined list of procedures, with no upfront payment and no overall limit. This benefit is subject to authorisation and the Scheme\u2019s Rules.",
    "crucialRules": "Includes preventative and diagnostic consultations, fillings, extractions, cleanings and x-rays. . For members who are 13 years old or older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment from your available day-to-day benefits.",
    "notes": "We pay for basic dental treatment done in the dentist's or dental specialist's rooms at 100% of the Discovery Health Rate from your available day-to-day benefits (Medical Savings Account and Above Threshold Benefit).We pay all dental appliances and their placement, as well as orthodontic treatment (including related accounts for orthognathic surgery) at 100% of the Discovery Health Rate. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "De Beers",
    "plan": "Smart Series: Essential Dynamic Smart Plan",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "yes",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "In hospital dental cover On the Classic Plan, except where approved for severe dental and oral surgery, you need to pay a portion of your hospital or day clinic account upfront for dental admissions. This amount varies, depending on your age and the place of treatment. We do not cover in-hospital dental treatment on the Essential Smart Plan. ",
    "preAuth": "Cover for basic dental treatment : What we pay for We pay for basic dental treatment done in the dentist's or dental specialist's rooms from your available day-to-day benefits (Medical Savings Account) at 100% of the Discovery Health Rate. There is no overall limit for basic dental treatment. ",
    "crucialRules": "You have cover for one defined dental check-up at any dentist, dental therapist or oral hygienist with a fixed upfront payment for the check-up.",
    "notes": "For members 13 and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment from your available funds in your Medical Savings Account. The Severe Dental and Oral Surgery Benefit covers a defined list of procedures, with no upfront payment and no overall limit. This benefit is subject to authorisation and the Scheme's rules. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "De Beers",
    "plan": "Core Series: ",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "yes",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Your health plan does not cover day-to-day medical costs, you will have to pay for these dental treatment costs",
    "preAuth": "",
    "crucialRules": "You have to pay the cost of all dental appliances and their placements as well as orthodontic treatment (including the related accounts for orthognathic surgery). ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "De Beers",
    "plan": " Comprehensive series: Classic | Classic Delta | Essential | Essential Delta | Classic Smart",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Severe dental treatment cover The Severe Dental and Oral Surgery Benefit covers a defined list of procedures, with no upfront payment and no overall limit. This benefit is subject to authorisation and the Scheme's rules. If you are on the Classic Delta Comprehensive or Essential Delta Comprehensive plans, you need to use a hospital in the Delta Network. If you are on the Classic Smart Comprehensive plan, you need to use a hospital in the Smart Network.",
    "preAuth": "There is no overall limit for basic dental treatment.We pay your dental appliance and orthodontic claims up to a yearly limit of R30 750 a person. If you join the Scheme after January, you will have a lower limit because we calculate the limit by counting the remaining months in the year. On Classic Smart Comprehensive these benefits apply once the Annual Threshold is reached.. On Classic Smart Comprehensive these benefits apply once the Annual Threshold is reached.",
    "crucialRules": "For members 13 years and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment, from your available day-to-day benefit",
    "notes": "Essential Smart Plan In-hospital dental treatment is not covered on the Essential Smart Plan. For members 13 years and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment, from your available day-to-day benefits. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "De Beers",
    "plan": "Keycare Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1285"
      ],
      "fax": [
        " it to 086 687 1285"
      ],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "yes",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised instrumentation will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. No Panorex. No Crown &, Bridges, Ortho, Implants or Roor canal Fillings. ",
    "preAuth": "8107 / 8112 Pre-authorisation when quantity is more than the following: \u2022 With 8101, 8112 x 2 and 8107 x 1 \u2022 With 8104, 8112 x 1 or 8107 x 1 \u2022 No consult 8112 x 1 or 8107 x 1 \u2022 Maximum quantity of 7 per year. Maximum 1 x 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. More than 4 restorations per year. \u2022 Two or more 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays. 1 Restoration code per tooth number in a 9 month period. ",
    "crucialRules": "8159, 8155,8161Once per 6 months per member from date of service. 8132 \u2022 Maximum of 1 per treatment date.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Dis-Chem Health",
    "plan": "MyHealth Core",
    "administrator": "Prime Cure Health",
    "contactInfo": {
      "email": [
        "dischemhealth@kaelo.co.za"
      ],
      "fax": [],
      "phone": [
        "'0861029894"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic and other extra oral x-rays (e.g., 8115) does not form part of the benefit schedule. ",
    "preAuth": "8935 Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202). Extractions - Pre-Authorisation is needed for four or more extractions per beneficiary per year. One (1) surgical extraction per beneficiary per year. Must be authorised . 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth. 8162 needs to be authorised. Fillings - White/Resin (anterior only) or Amalgam posterior - Note: Posterior Resin fillings will be paid for at Amalgam rates. Denture are limited to pre-authorisation. Dentures, reline, rebase and all specialised dentistry - Partial dentures. Conscious sedation pre auth. Inhalation sedation: under 7 years of age.",
    "crucialRules": "8101 Consultation oral examination Once per year, 8104 Examination for a specific problem not requiring full mouth examination. 8107, 8112 Intra oral radiographs/Bite Wing X-rays, per film (maximum of four X-rays per family per year). 8155 Polishing only Once a year for ages 3-12yrs.8159 Scaling and polishing Once a year over the age 12 yrs 8161 Topical application of fluoride Once a year for ages 3 to 12 yrs. 8132 Gross pulpal debridement, primary & permanent teeth (emergency root canal). Pre-authorisation if a patient neesd repairs to four or more teeth (including fillings) per person per year. One (1) consultations/ examinations code 8101 per beneficiary per year.",
    "notes": "8201, 8202 Extraction, single tooth. Code 8201 is charged for the first extraction in a quadrant. Maximum for 8201 & 8202 is three (3), thereafter pre-authorisation required for the first extraction in a quad-rant. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Dis-Chem Health",
    "plan": "MyHealth Plus",
    "administrator": "Prime Cure Health",
    "contactInfo": {
      "email": [
        "dischemhealth@kaelo.co.za"
      ],
      "fax": [],
      "phone": [
        "'0861029895"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic and other extra oral x-rays (e.g., 8115) does not form part of the benefit schedule. ",
    "preAuth": "8935 Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202). Extractions - Pre-Authorisation is needed for four or more extractions per beneficiary per year. One (1) surgical extraction per beneficiary per year. Must be authorised . 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth. 8162 needs to be authorised. Fillings - White/Resin (anterior only) or Amalgam posterior - Note: Posterior Resin fillings will be paid for at Amalgam rates. Denture are limited to pre-authorisation. Dentures, reline, rebase and all specialised dentistry - Partial dentures. Conscious sedation pre auth. Inhalation sedation: under 7 years of age.",
    "crucialRules": "8101 Consultation oral examination Once per year, 8104 Examination for a specific problem not requiring full mouth examination. 8107, 8112 Intra oral radiographs/Bite Wing X-rays, per film (maximum of four X-rays per family per year). 8155 Polishing only Once a year for ages 3-12yrs.8159 Scaling and polishing Once a year over the age 12 yrs 8161 Topical application of fluoride Once a year for ages 3 to 12 yrs. 8132 Gross pulpal debridement, primary & permanent teeth (emergency root canal). Pre-authorisation if a patient neesd repairs to four or more teeth (including fillings) per person per year. One (1) consultations/ examinations code 8101 per beneficiary per year.",
    "notes": "8201, 8202 Extraction, single tooth. Code 8201 is charged for the first extraction in a quadrant. Maximum for 8201 & 8202 is three (3), thereafter pre-authorisation required for the first extraction in a quad-rant. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Discovery",
    "plan": "Keycare Start",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1285"
      ],
      "fax": [
        " it to 086 687 1285"
      ],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised instrumentation will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. No Panorex. No Crown &, Bridges, Ortho, Implants or Roor canal Fillings. ",
    "preAuth": "8108 / 8112 Pre-authorisation when quantity is more than the following: \u2022 With 8101, 8112 x 2 and 8107 x 1 \u2022 With 8104, 8112 x 1 or 8107 x 1 \u2022 No consult 8112 x 1 or 8107 x 1 \u2022 Maximum quantity of 7 per year. Maximum 1 x 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. More than 4 restorations per year. \u2022 Two or more 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays. 1 Restoration code per tooth number in a 9 month period. ",
    "crucialRules": "8159, 8155,8161Once per 6 months per member from date of service. 8132 \u2022 Maximum of 1 per treatment date.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Discovery",
    "plan": "KEYCARE START REGIONAL OPTION",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1286"
      ],
      "fax": [
        " it to 086 687 1286"
      ],
      "phone": [
        "0860445567"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised instrumentation will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. No Panorex. No Crown &, Bridges, Ortho, Implants or Root canal Fillings. No dental in Theatre. ",
    "preAuth": "8109 / 8112 Pre-authorisation when quantity is more than the following: \u2022 With 8101, 8112 x 2 and 8107 x 1 \u2022 With 8104, 8112 x 1 or 8107 x 1 \u2022 No consult 8112 x 1 or 8107 x 1 \u2022 Maximum quantity of 7 per year. Maximum 1 x 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. More than 4 restorations per year. \u2022 Two or more 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays. 1 Restoration code per tooth number in a 9 month period. ",
    "crucialRules": "8159, 8155,8161Once per 6 months per member from date of service. 8132 \u2022 Maximum of 1 per treatment date.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Discovery",
    "plan": "FLEXICARE, FLEXICARE PLUS AND FLEXICARE HOUSEHOLD OPTIONS",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1285"
      ],
      "fax": [
        " it to 086 687 1285"
      ],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised instrumentation will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. No Panorex. No Crown &, Bridges, Ortho, Implants or Roor canal Fillings. No Specialized Dentistry. No Crowns & bridges, Implants,Ortho, Dentures or Root canal fillings. No Dental in Theatre .",
    "preAuth": "Restoration Codes: Max of 3 restorations per annum. 2 Posterior restorations (resin or amalgam) per member per 365 days. Posterior resin restorations covered at amalgam rates Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised. 1 anterior restoration per member per 365 days. 1 Restoration code per tooth number in a 9-month period. ",
    "crucialRules": "8101 Once per 6 months per member from date of service. 8104 1 per member per 365 days. Not within 4 weeks of 8101, 8102, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). 8107 /8112 2 per 8101 \u2022 1 per 8104 \u2022 No consult 8112 x 1 or 8107 x 1 \u2022 If consultation is not covered no benefit. \u2022 Maximum quantity of 7 per year. 8159, 8155 Once per 6 months per member from date of service. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Discovery",
    "plan": "TFG",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1286"
      ],
      "fax": [
        " it to 086 687 1286"
      ],
      "phone": [
        "0860445567"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised instrumentation will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. No Panorex. No Crown &, Bridges, Ortho, Implants or Root canal Fillings. No dental in Theatre. ",
    "preAuth": "8109 / 8112 Pre-authorisation when quantity is more than the following: \u2022 With 8101, 8112 x 2 and 8107 x 1 \u2022 With 8104, 8112 x 1 or 8107 x 1 \u2022 No consult 8112 x 1 or 8107 x 1 \u2022 Maximum quantity of 7 per year. Maximum 1 x 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. More than 4 restorations per year. \u2022 Two or more 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays. 1 Restoration code per tooth number in a 9 month period. ",
    "crucialRules": "8159, 8155,8161Once per 6 months per member from date of service. 8132 \u2022 Maximum of 1 per treatment date.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Enablemed",
    "plan": "Makoti Primary",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "no",
    "exclusions": "Plastic Dentures No benefit. Partial Chrome Cobalt Frame Dentures No benefit ",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required. Fillings Benefit for fillings: Benefit for more than three fillings per beneficiary: Subject to pre-notification. Plastic Dentures Pre-authorisation required. Hospitalisation Pre-authorisation required. Sedation in Dental Rooms pre-authorisation required. ",
    "crucialRules": "One check-up per beneficiary per year.X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: One scale and polish treatment per beneficiary per year Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age. Benefit for extractions only available for procedure code 8201. ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Enablemed",
    "plan": "Makoti Comprehensive",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and Associated Laboratory Costs No benefit",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days. Benefit for more than three fillings per beneficiary: Subject to pre-notification. Hospitalisation (General Anaesthetic) Pre-authorisation required. Deep Sedation in Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment.. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required One partial frame (an upper or a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required Benefit for crowns will be granted once per tooth in a 5-year period. ",
    "crucialRules": "One check-up per beneficiary per year.X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: One scale and polish treatment per beneficiary per year Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age. Benefit for extractions only available for procedure code 8201. ",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required) Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard. (no pre-authorisation required) Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Engen",
    "plan": "Engen",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0800 001 615 service@engenmed.co.za"
      ],
      "fax": [],
      "phone": [
        "'0800 001 615"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bleaching of vital teeth, metal inlays in dentures and front teeth. Crowns no benefits. Implants No benefit ",
    "preAuth": "Basic Dentistry No pre-authorisation required. Specialised dentistry. Includes: inlays,crowns, bridges, study models, metal-base dentures and the repair thereof, oral medicine, periodontics, orthodontics, None (unless in-hospital treatment is required) Benefit confirmation is required for orthodontic work. Dentistry: maxillo-facial surgery. In-hospital dentistry \u2013 theatre and Anaesthetist accounts for children under the age of 8 years.",
    "crucialRules": "100% Fund rate from Insured Benefits",
    "notes": "Benefit confirmation is required for advanced dental. Bas",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Essential Employee Benefits (EEB)",
    "plan": "PEP EXECUTIVE PLAN ESSENTIAL PLAN COMBINATION PLAN ACKERMANS ESSENTIAL PLAN",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "0861707070",
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. Maximum of 4 fillings per member per annum. 1 Restoration code per tooth number in 365 days. \u2022 Benefits for re-treatment of a tooth subject to managed care protocols. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. ",
    "crucialRules": "8101 Full mouth examinationOnce every 6 months per member from date of service. Maximum of 4 x-rays per member per annum. 8159,8155, 8161 Once in 6 months per member from date of service. ",
    "notes": "There will be no benefit for out-of-network visits and treatment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Essentialmed",
    "plan": "BASIC OPTION BALANCE OPTION & BALANCE PLUS OPTION BOOST PLUS, COMPREHENSIVE & DAY-TO-DAY OPTION 55 PLUS 4 U OPTION ESSENTIAL LITE DD17, DD21, DD25 & DD29",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "0861707070",
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. No Crown & bridge. Dentures, Metal plate Dentures, Ortho, Implants, Theatre or Root canal fillings.",
    "preAuth": "Pre-authorisation required for more than 4 restorations per annum. Three or Four surface restorations on wisdom teeth require x-rays. Examination or consultation for a specific problem (Motivation required for code 8104 if no covered clinical procedure is charged). Extraction Codes Only 1 per quadrant. Authorisation required for more than 4 or more extractions. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 Examination Not within 4 weeks of 8101, 8104. 8107 / 8112- 2 per member per consultation not more than 4 per annum. 8155, 8159, 8161, 8162 Once every 6 months per member from date of service. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Essentialmed",
    "plan": "DD 33,37,41,45",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "0861707070",
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered .",
    "preAuth": "Pre-authorisation required for more than 4 restorations per annum. Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. 1 Restoration code per tooth number in a 9-month time period. Extraction Only 1 per quadrant. Authorisation required for more than 4 or more extractions.. Authorisation required for more than 4 or more extractions.Crown (including Lab Costs) (pre-authorisation required) 8376 - 8617 1 Crown per member up to R4 950 per event within a 24-month period.",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 Examination Not within 4 weeks of 8101, 8104. 8107 / 8112 not more than 4 per annum. 8155, 8159, 8161 Once every 6 months per member from date of service. 8631 - 8633 1 Root canal treatment up to R2 200 per member per annum ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit. 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Essentialmed",
    "plan": "DD49, DD53, DD57 & DD61",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "0861707070",
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered.",
    "preAuth": "Pre-auth for more than 4 restorations per annum. Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. 1 Restoration code per tooth number in a 9-month time period. Extraction Only 1 per quadrant. Authorisation required for more than 4 or more extractions. Examination or consultation for a specific problem (Motivation required for code 8104 if no covered clinical procedure is charged). Extraction Codes Only 1 per quadrant. Authorisation required for more than 4 or more extractions. Root Canal 2 Root canal treatments up to R2 200 per event per member per annum. Crown 2 Crowns per member. Dentures 1 Denture to the value of R2 000 per policy per annum. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8107 / 8112 2 per member per consultation not more than 4 per annum. 8155,8159,8161,8162 Once every 6 months per member from date of service.",
    "notes": " 1. Lower rate applies to non network provider 2. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Myfed",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za, 086 1100 220"
      ],
      "fax": [],
      "phone": [
        "0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No metal base to complete or partial dentures allowed. No Panorex.",
    "preAuth": "Any 4 amalgam restorations per member per year \uf0b7 Any four resin restorations per member per year (anterior) . Any 4 resin restorations per member per year . All restorations are restricted to a total of 3 per year per patient without pre-authorisation and a total of 3 can be made up of any combination of the restorative codes. One set of dentures allowed Pre \u2013 authorisation required. ",
    "crucialRules": "8104 Consultation for a specific problem: 1 per beneficiary per annum. 8101 Two per member per year. 8107/8112 Maximum 2 per member per year. 8159 Two per member per year. 8161 Between the ages of 3-12 years. Two per member per year. 8163 Patients younger than 14; maximum of 8 per year; 2 per quadrant. Any four fillings per memeber per year. 8201 & 8202 Any four non surgical extractions per memeber per year. 8937 Surgical removal of tooth Quantity limit of 4, restricted to posterior permanent teeth. 8935 one per member per year.",
    "notes": " 1. Lower rate applies to non network provider 2. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Flexifed 2",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za, 086 1100 220"
      ],
      "fax": [],
      "phone": [
        "'0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No metal base to complete or partial dentures allowed. No Panorex.",
    "preAuth": "Any 4 amalgam restorations per member per year \uf0b7 Any four resin restorations per member per year (anterior) . Any 4 resin restorations per member per year . All restorations are restricted to a total of 3 per year per patient without pre-authorisation and a total of 3 can be made up of any combination of the restorative codes. One set of dentures allowed Pre \u2013 authorisation required. In-hospital dentistry benefit for children under 7",
    "crucialRules": "8104 Consultation for a specific problem: 1 per beneficiary per annum. 8101 Two per member per year. 8107/8112 Maximum 2 per member per year. 8159 Two per member per year. 8161 Between the ages of 3-12 years. Two per member per year. 8163 Patients younger than 14; maximum of 8 per year; 2 per quadrant. Any four fillings per memeber per year. 8201 & 8202 Any four non surgical extractions per memeber per year. 8937 Surgical removal of tooth Quantity limit of 4, restricted to posterior permanent teeth. 8935 one per member per year.",
    "notes": " 1. Lower rate applies to non network provider 2. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Flexifed3",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za, 086 1100 220"
      ],
      "fax": [],
      "phone": [
        "'0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No metal base to complete or partial dentures allowed. No Panorex.",
    "preAuth": "Any 4 amalgam restorations per member per year \uf0b7 Any four resin restorations per member per year (anterior) . Any 4 resin restorations per member per year . All restorations are restricted to a total of 3 per year per patient without pre-authorisation and a total of 3 can be made up of any combination of the restorative codes. One set of dentures allowed Pre \u2013 authorisation required. In-hospital dentistry benefit for children under 7",
    "crucialRules": "8104 Consultation for a specific problem: 1 per beneficiary per annum. 8101 Two per member per year. 8107/8112 Maximum 2 per member per year. 8159 Two per member per year. 8161 Between the ages of 3-12 years. Two per member per year. 8163 Patients younger than 14; maximum of 8 per year; 2 per quadrant. Any four fillings per memeber per year. 8201 & 8202 Any four non surgical extractions per memeber per year. 8937 Surgical removal of tooth Quantity limit of 4, restricted to posterior permanent teeth. 8935 one per member per year.",
    "notes": " 1. Lower rate applies to non network provider 2. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Fedhealth",
    "plan": "FlexiFed 1 Hospital Plan",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "authorisations@fedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 002 153"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No basic dentistry. No Specialised dentisrty. In-hospital dentistry benefit for children under 7, No benefit.",
    "preAuth": "Surgical extraction of impacted wisdom teeth, You pay a co-payment of R5 100 on the hospital bill",
    "crucialRules": "8104 Consultation for a specific problem: 1 per beneficiary per annum. 8101 Two per member per year. 8107/8112 Maximum 2 per member per year. 8159 Two per member per year. 8161 Between the ages of 3-12 years. Two per member per year. 8163 Patients younger than 14; maximum of 8 per year; 2 per quadrant.",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Fedhealth",
    "plan": "MaxiFed & MaxiFed Plus & Maxi ma Exec",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "authorisations@fedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 002 153"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered.",
    "preAuth": "In-hospital dentistry benefit for children under 7. Authorisation for all advanced dentistry.",
    "crucialRules": "Specialised dentistry R 8720,00 per beneficiary per year and R 24 700,00 per family per year. Panorex, once every 3 year.",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Fedhealth",
    "plan": "FlexiFed 4",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "authorisations@fedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 002 153"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered.",
    "preAuth": "In-hospital dentistry benefit for children under 7years. Dentistry (Advanced): inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, pre authorisation.",
    "crucialRules": "We pay for dentistry performed in-hospital for children up to the age of 7. The hospital account and anaesthetist costs are paid from the in-hospital benefit while the dentist\u2019s account comes from day-to-day benefits.",
    "notes": "Dentistry (basic) Paid from Savings, OHEB and threshold. Unlimited once threshold is reached. Panorex once every 3 years.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Gems",
    "plan": "Beryl",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "'enquiries@gems.gov.za'"
      ],
      "fax": [],
      "phone": [
        "0860104121 / Fax: 0860 104 123"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "",
    "crownsOrBridges": "",
    "implants": "",
    "surgicalExtractions": "",
    "exclusions": " No benefit available for metal frame dentures. No benefit for retreatment of a previously root canal treated tooth",
    "preAuth": "surgical procedures under general anaesthesia, or sedation in the rooms: certain maxillofacial surgical procedures. surgical removal of impacted teeth. \u2022 Subject to pre-authorisation. General anaesthesia only applicable to Beneficiaries: \u2022 up to (and including) the age of six (6) years. Conscious sedation in rooms for dental services: Beneficiaries up to and including the age of nine (9) years; Plastic dentures are subject to pre-authorisation One (1) set* of plastic dentures. . Pre-authorisation is needed for more than four (4) fillings. ",
    "crucialRules": "Two (2) consultation/examination and preventative treatment episodes per beneficiary per year. Limited to a maximum of four (4) restorations per beneficiary per year, once per tooth in 720 days. Root canal therapy is limited to one (1) complete event per beneficiary in 12 months. Panoramic radiograph/images are limited to one (1) per beneficiary every three (3) years. Periapical X-rays are limited to six (6) per beneficiary per annum. \u2022 Bitewing X-rays are limited to four (4) per beneficiary per annum. 8155 Two (2) per beneficiary per 12 months; i.e. one (1) every six (6) months; cannot be charged with 8159 in the same year. 8159 Two (2) per beneficiary per 12 months; i.e. one (1) every six (6). 8161 once per beneficiary per 12 months. 8163 A maximum of two (2) dental fissuresealants per quadrant on posterior permanent teeth only. Any four (4) non-surgical extractions per beneficiary per year; only if clinically indicated. Root canal obturation \u2013 anteriors and premolars, Limited to once per tooth per lifetime and one (1) complete root canal therapy event (one tooth only) per beneficiary per 12 months. ",
    "notes": " Patient written consent needed for procedures not covered. 8937 Maximum of two (2) removals. 8213 Maximum of two (2) procedure \u2013 more than one (1) requires clinical motivation. Any four (4) non-surgical extractions per beneficiary per year; only if clinically indicated. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Gems",
    "plan": "Tanzanite One",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "'enquiries@gems.gov.za'"
      ],
      "fax": [],
      "phone": [
        "0860104121 / Fax: 0860 104 123"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " No benefit available for metal frame dentures. No benefit for retreatment of a previously root canal treated tooth",
    "preAuth": "surgical procedures under general anaesthesia, or sedation in the rooms: certain maxillofacial surgical procedures. surgical removal of impacted teeth. \u2022 Subject to pre-authorisation. General anaesthesia only applicable to Beneficiaries: \u2022 up to (and including) the age of six (6) years. Conscious sedation in rooms for dental services: Beneficiaries up to and including the age of nine (9) years; Plastic dentures are subject to pre-authorisation One (1) set* of plastic dentures. . Pre-authorisation is needed for more than four (4) fillings. ",
    "crucialRules": "Two (2) consultation/examination and preventative treatment episodes per beneficiary per year. Limited to a maximum of four (4) restorations per beneficiary per year, once per tooth in 720 days. Root canal therapy is limited to one (1) complete event per beneficiary in 12 months. Panoramic radiograph/images are limited to one (1) per beneficiary every three (3) years. Periapical X-rays are limited to six (6) per beneficiary per annum. \u2022 Bitewing X-rays are limited to four (4) per beneficiary per annum. 8155 Two (2) per beneficiary per 12 months; i.e. one (1) every six (6) months; cannot be charged with 8159 in the same year. 8159 Two (2) per beneficiary per 12 months; i.e. one (1) every six (6). 8161 once per beneficiary per 12 months. 8163 A maximum of two (2) dental fissuresealants per quadrant on posterior permanent teeth only. Any four (4) non-surgical extractions per beneficiary per year; only if clinically indicated. Root canal obturation \u2013 anteriors and premolars, Limited to once per tooth per lifetime and one (1) complete root canal therapy event (one tooth only) per beneficiary per 12 months. ",
    "notes": " Patient written consent needed for procedures not covered. 8937 Maximum of two (2) removals. 8213 Maximum of two (2) procedure \u2013 more than one (1) requires clinical motivation. Any four (4) non-surgical extractions per beneficiary per year; only if clinically indicated. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Gems",
    "plan": "Emarald / EMERALD VALUE",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "enquiries@gems.gov.za'"
      ],
      "fax": [],
      "phone": [
        "0860436777 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures \u2022 Snoring apparatus \u2022 Clasp or rest \u2013 cast gold \u2022 Clasp or rest \u2013 wrought gold \u2022 Inlay in denture \u2022 Metal base to full dentures \u2022 Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. \u2022 On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components, restorations/crowns/bridges/dentures/repairs associated with implants). Occlusal adjustment minor (pre-authorisation necessary for major occlusal adjustment). ",
    "preAuth": "Pre-authorisation is required for all other specialised dentistry procedures. \u2022 Implant supported crowns, bridges, and dentures are subject to pre- authorisation. the following surgical procedures under general anaesthesia, or sedation in the rooms certain maxillofacial surgical procedures. surgical removal of impacted teeth (In Hospital and Out-of Hospital). \u2022 Subject to pre-authorisation, General anaesthesia is only applicable to the following beneficiaries: up to and including the age of six (6) years. Conscious sedation in rooms: Only applicable to beneficiaries up to and including the age of nine (9) years; subject to pre-authorisation. One (1) set of full, or full upper or full lower, or partial upper and/or partial lower plastic dentures once every four (4) years; relines, rebase, soft base every two (2) years; metal framework every five (5) years. Crowns and bridges Pre-authorisation is required. Implant supported crowns Benefit available for Phase 3 implant crowns, bridges and dentures, subject to pre authorisations. surgical removal of impacted teeth (In Hospital and Out-of-Hospital). Pre-authorisation is required ",
    "crucialRules": "Panoramic radiograph: Benefit from the age of six \u2013 maximum one every 36 months. 8163 Maximum of two (2) per quadrant and once every two (2) years per tooth Metal frames for partial dentures one (1) per jaw and once every five (5) years. Pre-authorisation is required for all therspecialised dentistry procedures. Two (2) annual consultations per beneficiary, one (1) every six (6) months. 8107 (periapical): limited to 10 per beneficiary per 12 months. 8112 (bitewing): Maximum of six (6) per 12 months. 8159 Once every six (6) months \u2013 from the age of 10 only. 8155 Once every six (6) months from the age of three (3) to the age of 16. 8161 F luoride three (3) to the age of 11, once every six (6) months. 8162 f luoride \u2013 adult): From the age of 12 to the age of 16, once every six (6) months. Restorations/fillings Benefits available where clinically indicated \u2013 once per tooth in 720 days. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Gems",
    "plan": "Onyx",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "enquiries@gems.gov.za"
      ],
      "fax": [],
      "phone": [
        "0860436777 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures \u2022 Snoring apparatus \u2022 Clasp or rest \u2013 cast gold \u2022 Clasp or rest \u2013 wrought gold \u2022 Inlay in denture \u2022 Metal base to full dentures \u2022 Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. \u2022 On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components, restorations/crowns/bridges/dentures/repairs associated with implants). Occlusal adjustment minor (pre-authorisation necessary for major occlusal adjustment). ",
    "preAuth": "Pre-authorisation is required for all other specialised dentistry procedures. \u2022 Implant supported crowns, bridges, and dentures are subject to pre- authorisation. the following surgical procedures under general anaesthesia, or sedation in the rooms certain maxillofacial surgical procedures. surgical removal of impacted teeth (In Hospital and Out-of Hospital). \u2022 Subject to pre-authorisation, General anaesthesia is only applicable to the following beneficiaries: up to and including the age of six (6) years. Conscious sedation in rooms: Only applicable to beneficiaries up to and including the age of nine (9) years; subject to pre-authorisation. One (1) set of full, or full upper or full lower, or partial upper and/or partial lower plastic dentures once every four (4) years; relines, rebase, soft base every two (2) years; metal framework every five (5) years. Crowns and bridges Pre-authorisation is required. Implant supported crowns Benefit available for Phase 3 implant crowns, bridges and dentures, subject to pre authorisations. surgical removal of impacted teeth (In Hospital and Out-of-Hospital). Pre-authorisation is required ",
    "crucialRules": "Panoramic radiograph: Benefit from the age of six \u2013 maximum one every 36 months. 8163 Maximum of two (2) per quadrant and once every two (2) years per tooth Metal frames for partial dentures one (1) per jaw and once every five (5) years. Pre-authorisation is required for all therspecialised dentistry procedures. Two (2) annual consultations per beneficiary, one (1) every six (6) months. 8107 (periapical): limited to 10 per beneficiary per 12 months. 8112 (bitewing): Maximum of six (6) per 12 months. 8159 Once every six (6) months \u2013 from the age of 10 only. 8155 Once every six (6) months from the age of three (3) to the age of 16. 8161 F luoride three (3) to the age of 11, once every six (6) months. 8162 f luoride \u2013 adult): From the age of 12 to the age of 16, once every six (6) months. Restorations/fillings Benefits available where clinically indicated \u2013 once per tooth in 720 days. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Gems",
    "plan": "Ruby",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "'enquiries@gems.gov.za"
      ],
      "fax": [],
      "phone": [
        "0860436777 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "YES",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures \u2022 Snoring apparatus \u2022 Clasp or rest \u2013 cast gold \u2022 Clasp or rest \u2013 wrought gold \u2022 Inlay in denture \u2022 Metal base to full dentures \u2022 Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. \u2022 On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components, restorations/crowns/bridges/dentures/repairs associated with implants). Occlusal adjustment minor (pre-authorisation necessary for major occlusal adjustment). ",
    "preAuth": "Pre-authorisation is required for all other specialised dentistry procedures. \u2022 Implant supported crowns, bridges, and dentures are subject to pre- authorisation. the following surgical procedures under general anaesthesia, or sedation in the rooms certain maxillofacial surgical procedures. surgical removal of impacted teeth (In Hospital and Out-of Hospital). \u2022 Subject to pre-authorisation, General anaesthesia is only applicable to the following beneficiaries: up to and including the age of six (6) years. Conscious sedation in rooms: Only applicable to beneficiaries up to and including the age of nine (9) years; subject to pre-authorisation. One (1) set of full, or full upper or full lower, or partial upper and/or partial lower plastic dentures once every four (4) years; relines, rebase, soft base every two (2) years; metal framework every five (5) years. Crowns and bridges Pre-authorisation is required. Implant supported crowns Benefit available for Phase 3 implant crowns, bridges and dentures, subject to pre authorisations. surgical removal of impacted teeth (In Hospital and Out-of-Hospital). Pre-authorisation is required ",
    "crucialRules": "Panoramic radiograph: Benefit from the age of six \u2013 maximum one every 36 months. 8163 Maximum of two (2) per quadrant and once every two (2) years per tooth Metal frames for partial dentures one (1) per jaw and once every five (5) years. Pre-authorisation is required for all therspecialised dentistry procedures. Two (2) annual consultations per beneficiary, one (1) every six (6) months. 8107 (periapical): limited to 10 per beneficiary per 12 months. 8112 (bitewing): Maximum of six (6) per 12 months. 8159 Once every six (6) months \u2013 from the age of 10 only. 8155 Once every six (6) months from the age of three (3) to the age of 16. 8161 F luoride three (3) to the age of 11, once every six (6) months. 8162 f luoride \u2013 adult): From the age of 12 to the age of 16, once every six (6) months. Restorations/fillings Benefits available where clinically indicated \u2013 once per tooth in 720 days. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Genesis",
    "plan": "Med 100 (Hospitaal plan with n additional benefits.)",
    "administrator": "Genesis",
    "contactInfo": {
      "email": [
        "0214474707"
      ],
      "fax": [],
      "phone": [
        "0860 10 20 10"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " excluding root canal treatment on wisdom teeth. ",
    "preAuth": "In Hospital: Cost up to 100% of Scheme Tariff for the surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth.Child beneficiaries, prior to attaining the age of 9 years, for extractions and fillings. Crowns, bridges or dentures limited to the lower of cost or Scheme Tariff, further limited to R5 750. Surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth .",
    "crucialRules": "Three (3) dental oral examinations \u2022 Six (6) fillings \u2022 Tooth extractions \u2022 Plain X-rays and/or wide angle / Panorex imaging limited to the lower of cost or Scheme Tariff further limited to R750 \u2022 Two (2) root canal treatments, excluding root canal treatment on wisdom teeth \u2022 \u2022 Two (2) scales and polishing \u2022 One (1) dental implant limited to R10 000 per three year financial year cycle of membership. ",
    "notes": "Two (2) root canal treatments. Six (6) fillings",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Genesis",
    "plan": "Med 200 (Hospitaal plan with n additional benefits.)",
    "administrator": "Genesis",
    "contactInfo": {
      "email": [
        "0214474707"
      ],
      "fax": [],
      "phone": [
        "0860 10 20 10"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Advanced dentistry (i.e. orthodontic treatment)",
    "preAuth": "child beneficiaries, prior to attaining the age of 9 years, for extractions and fillings. Limited to one (1) hospital admission per beneficiary p.a. Crowns, bridges or dentures limited to the lower of cost or Scheme Tariff, further limited to R5 750. Surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth. One (1) dental implant limited to R10 000 per three year financial year cycle of membership. ",
    "crucialRules": "Three (3) dental oral examinations \u2022 Six (6) fillings \u2022 Tooth extractions \u2022 Plain X-rays and/or wide angle / Panorex imaging limited to the lower of cost or Scheme Tariff further limited to R750 \u2022 Two (2) root canal treatments, excluding root canal treatment on wisdom teeth \u2022\u2022 Two (2) scales and polishing.",
    "notes": "Two (2) root canal treatments. Six (6) fillings. Hospital plan with generous day-to-day benefits. Doctors covered at 200% of Scheme Tariff. Bonus: Includes day-to-day dentistry benefits.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Genesis",
    "plan": "Med 200 Plus",
    "administrator": "Genesis",
    "contactInfo": {
      "email": [
        "0214474707"
      ],
      "fax": [],
      "phone": [
        "0860 10 20 10"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Advanced dentistry (i.e. orthodontic treatment)",
    "preAuth": "child beneficiaries, prior to attaining the age of 9 years, for extractions and fillings. Limited to one (1) hospital admission per beneficiary p.a. Crowns, bridges or dentures limited to the lower of cost or Scheme Tariff, further limited to R5 750. Surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth. One (1) dental implant limited to R10 000 per three year financial year cycle of membership. ",
    "crucialRules": "Three (3) dental oral examinations \u2022 Six (6) fillings \u2022 Tooth extractions \u2022 Plain X-rays and/or wide angle / Panorex imaging limited to the lower of cost or Scheme Tariff further limited to R750 \u2022 Two (2) root canal treatments, excluding root canal treatment on wisdom teeth \u2022\u2022 Two (2) scales and polishing.",
    "notes": "Two (2) root canal treatments. Six (6) fillings. Hospital plan with generous day-to-day benefits. Doctors covered at 200% of Scheme Tariff. Bonus: Includes day-to-day dentistry benefits.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "GENRIC:",
    "plan": "COMPREHENSIVE ADVANCED, COMPREHENSIVE PLUS, COMPREHENSIVE STANDARD, PRIMARY STANDARD & PRIMARY STANDARD WITH HOSPITAL PLAN ",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "0861707070",
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "no",
    "dentures": "no",
    "crownsOrBridges": "no",
    "implants": "no",
    "surgicalExtractions": "no",
    "exclusions": "no advanced dentistry benefits, no 8115 benefit.",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year. \u2022 Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. \u2022 1 Restoration code per tooth number in a 9-month period. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. Code 8107 / 8112 Two per member per visit not more than 4 per year . 8159 & 8155 Once per 6 months per member from date of service. ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Getsavvi B Plus",
    "plan": "GETSAVVI B GETSAVVI B PLUS GETSAVVI C",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. No panorex, advanced dental",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre auth. \u2022 More than 4 restorations per annum. \u2022 Three or Four surface restorations done on more than 2 teeth per visit. \u2022 More than 2 anterior restorations per visit. \u2022 Multiple restorations on an anterior tooth on the same service date. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. \u2022 Any 3 or 4 surface restorations on wisdom teeth",
    "crucialRules": "8101 Full mouth examination 1 Per annum with a 6-month interval per member from date of service. 8104 Examination Maximum 1 per visit Not within 4 weeks of 8101, 8104. 8107 Periapical x-ray & 8112 Bitewing x-ray Maximum 2 per visit. Only 8159 dentition (older than 12 years) 1 Per annum from date of service & 8161 Fluoride treatment (younger than 12 years). Once every 6 months per member from date of service. 8132 Emergency root canal 1 Per member per annum. ",
    "notes": ". 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered. 4. Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Getsavvi Dental Top-Up",
    "plan": "Getsavvi ",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. No panorex",
    "preAuth": " 8107 & 8112 Pre-auth required when quantity is more than the following: \u2022 With 8101, 8112x2 and 8107x1 \u2022 With 8104, 8112x1 or 8107x1 \u2022 No consult 8112x1 or 8107x1 \u2022 Maximum quantity of 7 per annum. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. Pre-auth required for: \u2022 More than 4 restorations per annum. \u2022 Two or more: 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 Multiple restorations on an anterior tooth on the same service date. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. Deviations will only be covered if pre-authorised. \u2022 Any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "8101 Full mouth examination Once every 6 months per member from date of service. 8104 Examination Not within 4 weeks of 8101, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). 8159 than 12 years) Once in 6 months per member from date of service. 8155 Polish 8161 Fluoride treatment (younger than 12 years). Once every 6 months per member from date of service. ",
    "notes": " 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Glencore",
    "plan": "Glencore",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        ""
      ],
      "fax": [],
      "phone": [
        "0860 00 21 41"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No specific exclusions.",
    "preAuth": "in hospital for children under eight (8) and for removal of impacted wisdom teeth. ",
    "crucialRules": "Panorex once every 3 years.",
    "notes": "Basic: Includes plastic dentures and basic dentistry performed in-hospital for children under eight (8) and for removal of impacted wisdom teeth. Advanced: Oral surgery, metal base dentures, inlays, crowns, bridges, study models, orthodontics, periodontics, prosthodontics, osseointegrated implants, orthognathic surgery and dental technician fees.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Health4me",
    "plan": "SILVER & GOLD",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za",
        "email: drnet@momentum.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff.",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth.",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Horizon",
    "plan": "HOSPITAL PLUS NETWORK PLAN",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "Tel: 0860 103 493"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff.",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth.",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Imperial Med",
    "plan": "IMPERIAL MOTUS MED HEALTH PLAN",
    "administrator": "Imperial Med",
    "contactInfo": {
      "email": [
        "enquiries@imperialmotusmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 105 221"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered .",
    "preAuth": "Pre-authorisation required , Inlays, onlays, veneers, crowns and bridges , Plastic dentures with a cast-metal framework , Dental implants,Wisdom teeth extractions. R13 310 per family per annum Pre-authorisation required.",
    "crucialRules": "Scaling and/or polishing and fluoride treatment Two per beneficiary per annum. Fissure sealing Once-off for permanent molars in persons under 24 years. Oral examination, Diagnostics (X-rays, etc.), Restorations (fillings),Non-surgical extractions, Root canal treatment. R4 660 per beneficiary per annum.",
    "notes": "Scaling and/or polishing: wo per beneficiary per annum",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Imperial Med",
    "plan": "IMPERIAL MOTUS MED BUDGET PLAN",
    "administrator": "Imperial Med",
    "contactInfo": {
      "email": [
        "enquiries@imperialmotusmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 105 221"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for: Preventative dentistry, Advanced dentistry, Dental implants, Orthodontic treatment, Orthodontic-related surgery, Dental implants.",
    "preAuth": "Specialised dentistry No benefit",
    "crucialRules": "Scaling and/or polishing and fluoride treatment Two per beneficiary per annum. Fissure sealing Once-off for permanent molars in persons under 24 years. Oral examination, Diagnostics (X-rays, etc.), Restorations (fillings),Non-surgical extractions, Root canal treatment. ",
    "notes": "Basic dentistry : Oral examination \u00bb Diagnostics (X-rays, etc.) Restorations (fillings) Non-surgical extractions \u00bb Root canal treatment. 85% of MSR R2 970 per family per annum. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Ingwe",
    "plan": "Primary Care Network and Ingwe Active Network Options",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff.",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth.",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Kaelo Health Corporate",
    "plan": "Kaelo Health Plus PHI Only 151",
    "administrator": "Primecure",
    "contactInfo": {
      "email": [
        ""
      ],
      "fax": [],
      "phone": [
        "0861497587"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic and other extra oral x-rays (e.g., 8115) does not form part of the benefit schedule. ",
    "preAuth": "8935 Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202). Extractions - Pre-Authorisation is needed for four or more extractions per beneficiary per year. One (1) surgical extraction per beneficiary per year. Must be authorised . 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth. 8162 needs to be authorised. Fillings - White/Resin (anterior only) or Amalgam posterior - Note: Posterior Resin fillings will be paid for at Amalgam rates. Denture are limited to pre-authorisation. Dentures, reline, rebase and all specialised dentistry - Partial dentures. Conscious sedation pre auth. Inhalation sedation: under 7 years of age.",
    "crucialRules": "8101 Consultation oral examination Once per year, 8104 Examination for a specific problem not requiring full mouth examination. 8107, 8112 Intra oral radiographs/Bite Wing X-rays, per film (maximum of four X-rays per family per year). 8155 Polishing only Once a year for ages 3-12yrs.8159 Scaling and polishing Once a year over the age 12 yrs 8161 Topical application of fluoride Once a year for ages 3 to 12 yrs. 8132 Gross pulpal debridement, primary & permanent teeth (emergency root canal). Pre-authorisation if a patient neesd repairs to four or more teeth (including fillings) per person per year. One (1) consultations/ examinations code 8101 per beneficiary per year.",
    "notes": "8201, 8202 Extraction, single tooth. Code 8201 is charged for the first extraction in a quadrant. Maximum for 8201 & 8202 is three (3), thereafter pre-authorisation required for the first extraction in a quad-rant. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Kaelo Health Corporate",
    "plan": "Kaelo Health Plus -SI 129",
    "administrator": "Primecure",
    "contactInfo": {
      "email": [
        ""
      ],
      "fax": [],
      "phone": [
        "0861497587"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic and other extra oral x-rays (e.g., 8115) does not form part of the benefit schedule. ",
    "preAuth": "8935 Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202). Extractions - Pre-Authorisation is needed for four or more extractions per beneficiary per year. One (1) surgical extraction per beneficiary per year. Must be authorised . 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth. 8162 needs to be authorised. Fillings - White/Resin (anterior only) or Amalgam posterior - Note: Posterior Resin fillings will be paid for at Amalgam rates. Denture are limited to pre-authorisation. Dentures, reline, rebase and all specialised dentistry - Partial dentures. Conscious sedation pre auth. Inhalation sedation: under 7 years of age.",
    "crucialRules": "8101 Consultation oral examination Once per year, 8104 Examination for a specific problem not requiring full mouth examination. 8107, 8112 Intra oral radiographs/Bite Wing X-rays, per film (maximum of four X-rays per family per year). 8155 Polishing only Once a year for ages 3-12yrs.8159 Scaling and polishing Once a year over the age 12 yrs 8161 Topical application of fluoride Once a year for ages 3 to 12 yrs. 8132 Gross pulpal debridement, primary & permanent teeth (emergency root canal). Pre-authorisation if a patient neesd repairs to four or more teeth (including fillings) per person per year. One (1) consultations/ examinations code 8101 per beneficiary per year.",
    "notes": "8201, 8202 Extraction, single tooth. Code 8201 is charged for the first extraction in a quadrant. Maximum for 8201 & 8202 is three (3), thereafter pre-authorisation required for the first extraction in a quad-rant. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Keyhealth",
    "plan": "silver",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "0860104926",
        "keyhealthenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "yes",
    "exclusions": "Partial Chrome Cobalt Frame Dentures No benefit. Crown & Bridge No benefit. Implants No benefit",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days, Multiple fillings: A treatment plan and X-rays may be required . Root Canal Therapy and Extractions Benefit subject to managed care protocols . Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Hospitalisation (General Anaesthetic) Pre-authorisation required General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment . General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. ",
    "crucialRules": "One check-up per beneficiary per year.X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: One scale and polish treatment per beneficiary per year . Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age. Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age ",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required) ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Keyhealth",
    "plan": "Gold",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "0860104926",
        "keyhealthenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants* and Associated Laboratory Costs No benefit",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days, Multiple fillings: A treatment plan and X-rays may be required . Root Canal Therapy and Extractions Benefit subject to managed care protocols . Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required One partial frame (an upper or a lower) per beneficiary in a 5-year period. Crown & Bridge Two Crowns per beneficiary per year. Surgery in the dental chair: Benefit subject to managed care protocols. Hospitalisation General anaesthetic benefit available for children under the age of 5 years . General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment . ",
    "crucialRules": "Two check-up per beneficiary per year.X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: Two scale and polish treatments per beneficiary per year (once every 6 months) . Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age. Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age ",
    "notes": " Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Keyhealth",
    "plan": "Platinum",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "0860104926",
        "keyhealthenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis \u2022 Root canal therapy on primary (milk) and on third molars (wisdom teeth) \u2022 Direct and indirect pulp capping procedures. . Diagnostic dentures and associated laboratory costs \u2022 Provisional dentures and associated laboratory costs \u2022 Snoring appliances \u2022 High impact acrylic \u2022 The metal base to full dentures Crowns on third molars \u2022 Crown and bridge procedures for cosmetic reasons. \u2022 Laboratory fabricated temporary crowns \u2022 Occlusal rehabilitations \u2022 Provisional crowns. Porcelain veneers and inlays, Implants on third molars (wisdom teeth) ",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days, Multiple fillings: A treatment plan and X-rays may be required . Root Canal Therapy and Extractions Benefit subject to managed care protocols . Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frame (an upper or a lower) per beneficiary in a 5-year period. Pre-authorisation required Crown & Bridge. Implants Pre-authorisation required . Surgery in the dental chair: Benefit subject to managed care protocols. Hospitalisation General anaesthetic benefit available for children under the age of 5 years . General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment . ",
    "crucialRules": "Two check-up per beneficiary per year.X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: Two scale and polish treatments per beneficiary per year (once every 6 months) . Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age. Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age ",
    "notes": " Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Keyhealth",
    "plan": "ESSENCE",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "0860104926",
        "keyhealthenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "NO basic or specialised dentistry.",
    "preAuth": "Hospitalisation Pre-authorisation required ONLY for the removal of impacted teeth under GA (code 8941) . Deep Sedation in the Dental Rooms Pre-authorisation required ONLY for the removal of impacted teeth (code 8941) ",
    "crucialRules": "8101 One check-up per beneficiary per year (not within 6 months from the previous year\u2019s consultation). 8107 and/or 8112 Limited to 4 X-rays in total per beneficiary per year NOTE: No benefit for Extra-oral x-rays ",
    "notes": " Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Keyhealth",
    "plan": "EQUILIBRIUM",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "0860104926",
        "keyhealthenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "no",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and Associated Laboratory Costs No benefit. Partial Chrome Cobalt Frame Dentures and Associated Laboratory Costs No benefit Crowns and Associated Laboratory Costs No benefit ",
    "preAuth": "Fillings: Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Pre-authorisation required: One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Pre-authorisation required for orthodontic treatment granted once per beneficiary per lifetime. Hospitalisation (General Anaesthetic) Pre-authorisation required. Sedation in the Dental Rooms. Pre-authorisation required. Root Canal Therapy and Extractions Benefit subject to managed care protocols. One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period Pre-authorisation required .eneral anaesthetic benefit available for children under the age of 5 years for extensive dental treatment General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. ",
    "crucialRules": "One check-up per beneficiary per year. Four X-rays in total per beneficiary per year . X-rays: Extraoral One per beneficiary in a 3-year period. One scale and polish treatment per beneficiary per year (once every 6 months). Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age. Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age. ",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Keyhealth",
    "plan": "Origin",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "0860104926",
        "keyhealthenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for Root Canal Therapy, Dentures, Specialised Dentistry and dental treatment In Hospital on the Origin option.",
    "preAuth": "Fillings: Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Extractions Benefit subject to managed care protocols. Hospitalisation (General Anaesthetic) Pre-authorisation required ONLY for the removal of impacted teeth under GA. Deep Sedation in the Dental Rooms Pre-authorisation required ONLY for the removal of impacted teeth ",
    "crucialRules": "One check-up per beneficiary per year Three specific (emergency) consultations per beneficiary per year. Four X-rays per beneficiary per year . X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: One scale and polish treatment per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age . ",
    "notes": " Patient written consent needed for procedures not covered. Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "La Health ",
    "plan": "Focus",
    "administrator": "Discovery (DRC)",
    "contactInfo": {
      "email": [
        " service@discovery.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Emergency root canal treatment. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. ",
    "preAuth": "More than 5 restorations per year (especially anterior teeth). 2 or more 3 or 4 surface restorations per visit. More than 2 anterior restorations per visit. Multiple restorations on an anterior tooth on the same service date. Posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised. Any 3 or 4 surface restorations on wisdom teeth. Denture Codes (Must be pre-authorised). Maximum 1 x 8201 per quadrant per 365 days \u2022 More than 4 extractions per visit require pre-authorisation. DENTURES Once every 4 years per member",
    "crucialRules": "8101 Once per 6 months per member from date of service. 8104 Not within 4 weeks of 8101, 8104 (not covered if no clinical procedure is charged). Maximum of 2 x-rays per visit per member when claimed with 8101. NB: If claimed with 8104 or no consultation maximum of 1 per visit date. 8159,8155,8161,8162,Once per 6 months per member from date of service. ",
    "notes": " Patient written consent needed for procedures not covered.1. Very basic option 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "La Health ",
    "plan": "Keyplus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax it to 086 687 1285"
      ],
      "fax": [
        " it to 086 687 1285"
      ],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "yes",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised instrumentation will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.. Replacement of non-carious amalgam with resin. Restorations on non-functional wisdom teeth. No Panorex. No Crown &, Bridges, Ortho, Implants or Root canal Fillings. ",
    "preAuth": "Maximum 1 x 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. Pre-authorisation and xrays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 1 Restoration code per tooth number in a 9 month period. RCT on posterior teeth limited to members 12 years and older. \u2022 Retreatment subject to pre authorisation and managed care protocols. Denture Codes (Detailed codes required) Once every 4 years per member.",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of 8101 (8102 not covered). (Motivation required for code 8104 if no covered clinical procedure is charged). 8107 / 8112 Maximum of 2 x-rays per visit per member. (Overall max of 7 per annum). 8115 One per member in a 3-year period from date of service. Code 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. ",
    "notes": "8269 Repair of denture . 8273 Impression to repair denture , Rebase complete or partial denture, Remodel complete or partial denture, Reline, Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after new denture) 365 days per member.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Malcor",
    "plan": "Plan D",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "SPECIALISED DENTISTRY NO BENEFITS",
    "preAuth": "Dentures, Hospitalisation and Moderate/Deep Sedation in Dental Rooms must be pre-authorised. Benefit for fillings: Granted once per tooth in 720 days. Benefit for more than three fillings per beneficiary: Subject to pre-notification. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Hospitalisation (General Anaesthetic) Pre-authorisation required . Deep Sedation in Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. ",
    "crucialRules": "One check-up per beneficiary per year. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: One scale and polish treatment per beneficiary per year. Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age.Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age.Benefit for extractions only available for procedure code 8201. Root Canal Therapy Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no preauthorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": "Surgery in the dental chair: Benefit subject to managed care protocols. Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Malcor",
    "plan": "Plan A, B, C",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860100698"
      ],
      "fax": [],
      "phone": [
        "0861123267"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Specialised dentistry pre authorisation. Dentistry(maxilla-facialprocedures). Preauthorisation required Conservative dentistry and specialised dentistry not covered in-hospital unless preauthorised. In theatre dentistry - Children under the age of 12 years.",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 365 days. Dentistry (conservative dentistry and specialised dentistry, inclusive of osseo-integrated implants as well as related sinus lift and bonegraph procedures). ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medicall",
    "plan": "CLASSIC, OPTIMA ESTABLISH AND OPTIMA ENHANCE OPTIONS",
    "administrator": "PHA Administrators",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex. NO ADVANCED DENTAL",
    "preAuth": "Restoration Codes: Posterior Amalgam and Resin restorations remunerated at the same tariff below. Pre-authorisation and x-rays required for: More than 4 restorations per year. More than one: 3 or 4 surface restoration per visit. More than 2 anterior restorations per visit. Multiple restorations on an anterior tooth on the same service date. Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised. Any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": " 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedVital",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Basic and Advanced dentistry. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. No Benefits. EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 7 YEARS: No Benefits.",
    "preAuth": "SEDATION: REMOVAL OF IMPACTED TEETH IN THE DENTIST\u2019S ROOMS: 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth, 8945 Surgical removal of impacted tooth - third and subsequent teeth Limited to the removal of impacted third molars once per life time. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA) : 8941 Surgical removal of impacted tooth - first tooth, 8943 Surgical removal of impacted tooth - second tooth, 8945 Surgical removal of impacted tooth - third and subsequent teeth Limited to the removal of impacted third molars once per life time. ",
    "crucialRules": "CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS. Limited to the removal of impacted third molars only. SEDATION: REMOVAL OF IMPACTED TEETH IN THE DENTIST\u2019S ROOMS. ",
    "notes": " 1. Very basic option Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedAdd & MedAdd Elect",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars. \uf0b7 Clear, well defined (no conecutting) radiographs are required to support crown authorisations. \uf0b7 Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations). No crown codes may be charged within 6 months of code 8136. IMPLANTS: No theatre or hospital benefit for placement of implants. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. No Benefits. ",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit. More than 4 extractions per visit require pre-authorisation. Surgical removal of tooth: Subject to pre-authorisation. RESTORATION CODES : One restoration per tooth number within 12 months from date of service. Pre-auth for: More than 4 restorations per year 2 or more, 3 or 4 surface restorations per visit. More than 2 anterior restorations per visit. Lower jaw posterior restorations to be performed across the midline on the same visit. 3 or 4 surface restorations on third molar teeth. INCISION AND DRAINAGE OF ABSCESS: Limited to treatment in the consultation rooms.ROOT CANAL TREATMENT :RCT limited to members 12 years and older. CROWNS AND BRIDGES:. METAL FRAME DENTURES: Two partial metal frame dentures . ORTHODONTICS: Only valid for members younger than 18 years. SEDATION: REMOVAL OF IMPACTED TEETH: authorisation. IN-HOSPITAL TREATMENT . t for children younger than 7 years of age and the removal of impacted third molars. Pre-auth more than the following: \u2022 More than 6 per member per annum. \u2022 Accompanying tariff code, 8104, 8112x1 or 8107x1. \u2022 No consultation, 8112 or 8107x1. \u2022 If 8115 claimed - 8107 & 8112 is not covered in the same visit. dentures One set of full/partial dentures. Two partial metal frame dentures. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 Not within 4 weeks of 8101, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). 8155,8159 Once per 6 months per member from date of service. 8163 Once per 6 months per member from date of service. 8115 One per member in a 3-year.",
    "notes": "*Conservative services for members younger than 18 years, as indicated in the protocol, will be paid from the member\u2019s annual dental benefit. All claims for members older than 18 years will be paid from the savings account",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": " MedElite",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "'0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars. IMPLANTS: No Benefits.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-auth. Surgical removal of residual root -per tooth Subject to pre-auth. Pre-authorisation and x-rays required for: \u2022 More than 4 restorations per year. \u2022 2 or more, 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 3 or 4 surface restorations on third molar teeth.Pre-auth more than the following: \u2022 More than 6 per member per annum . Two crown per family. METAL FRAME DENTURES two partial metal frame denture. SEDATION: REMOVAL OF IMPACTED TEETH removal of impacted third molars once per lifetime. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. REMOVAL OF IMPACTED TEETH in hoispital Once per lifetime. CHILDREN YOUNGER THAN 7 YEARS ",
    "crucialRules": " 8101 / 8104 Once per 6 months per member from date of service. 8159 /8155 Once per 6 months per member from date of service. 8163, Limited to once per tooth number per lifetime. RCT limited to members 12 years and older. One set of full/partial dentures. 8115 Panoramic x-ray One per member in a 3-year period from date of service. ",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged). Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. Patient written consent needed for procedures not covered .",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": "Medprime & MedPrime Elect",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "'0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "no",
    "surgicalExtractions": "Yes",
    "exclusions": "IMPLANTS No Benefits",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-auth. Surgical removal of tooth Subject to pre-auth. Pre-auth required for: \u2022 More than 4 restorations per year. \u2022 2 or more, 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 3 or 4 surface restorations on third molar teeth. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline \u2022 One restoration per tooth number within 12 months from date of service. RCT limited to members 12 years and older. \u2022 Retreatment subject to pre-auth. One set of full/partial dentures. Pre-auth 8107 / 8112 when quantity is more than the following: \u2022 More than 6 per member per annum 8104, 8112x1 or 8107x1, \u2022 No consultation, 8112x1 or 8107x1 \u2022 If 8115 claimed - 8107 & 8112 is not covered in the same visit.. One crown annually per family, One partial metal frame denture. CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS for children younger than 12 years of age and the removal of impacted third molars only. IN-HOSPITAL TREATMENT Limited to dental treatment for children younger than 7 years of age and the removal of impacted third molars.",
    "crucialRules": "8104 Examination Not within 4 weeks of 8101, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). 8101 Full mouth examination, Once per 6 months per member from date of service. 8159,8155 Once per 6 months per member from date of service. 8163 Fissure Sealant children older than 5 years and younger than 16 years Limited to 1st and 2nd permanent molars. \u2022 Limited to once per tooth number per lifetime. 8161 Fluoride treatment (limited to member from 5 years and younger than 13 years) Once per 6 months per member from date of service. ",
    "notes": "Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. \u2022 One reline or rebase per mem ber per 12 month period from date of service. 8115 Panoramic x-ray One per member in a 3-year period from date of service. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedPlus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "'0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for oral hygiene instructions/evaluation.No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.\uf0b7 Clear, well defined (no conecutting) radiographs are required to support crown authorisations. \uf0b7 Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations). ",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. 8937 Surgical removal of tooth Subject to pre-authorisation . Pre-auth for: \u2022 More than 4 restorations per year. \u2022 2 or more, 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 3 or 4 surface restorations on third molar teeth. \u2022 Lower jaw posterior fillings in volving anaesthetic cannot be performed across the midline \u2022 One restoration per tooth number within 12 months from date of service. RCT limited to members 12 years and older. \u2022 Retreatment subject to pre-auth.One set of full/partial dentures. 8107/8112 Pre-authorisation and motivation required prior to claims submission when quantity is more than the following: \u2022 More than 6 per member per annum irrespective of the tariff code used. \u2022 Accompanying tariff code, 8104, 8112x1 or 8107x1 \u2022 No consultation, 8112x1 or 8107x1 \u2022 If 8115 claimed - 8107 & 8112 is not covered in the same visit. Three crowns annually per family, Two partial metal frame dentures (an upper or/and a lower jaw). Two implants per member per 5 year period. CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS Limited to extensive dental treatment for children younger than 12 years of age and the removal of impacted third molars only. IN-HOSPITAL TREATMENT treatment for children younger than 7 years of age and the removal of impacted third molars. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 Examination Not within 4 weeks of 8101, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). 8159,8155 Once in every 6 months per member from date of service. 8163 \u2022 Limited to 1st and 2nd permanent molars. \u2022 Limited to once per tooth number per lifetime. 8161 Fluoride treatment (Limited to children older than 5 years and younger than 13 years of age) Once in every 6 months per member from date of service. 8115 Panoramic x-rayOne per member in a 3-year period",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged). Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. Patient written consent needed for procedures not covered .",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedElect (NETWORK PROVIDER AND HOSPITALS)",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "'0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for oral hygiene instructions/evaluation.No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. SPECIALISED DENTAL SERVICES: No Benefits, CROWNS AND BRIDGES, METAL FRAME DENTURES, ORTHODONTICS, MAXILLOFACIAL SURGERY AND ORAL PATHOLOGY. IMPLANTS. ",
    "preAuth": "8937 Surgical removal of tooth Subject to pre-authorisation. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-auth. 4 restorations per member. \u2022 Posterior resins not covered. Pre-auth required for: \u2022 2 or more, 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 3 or 4 surface restorations on third molar teeth. RCT Limited to 2 teeth annually per member. One set of plastic dentures (an upper and a lower jaw) per family . 8107/8112 4 x-rays annually per member. Pre-auth required prior to claims submission when quantity is more than 8104, 8112x1 or 8107x1 \u2022 No consultation, 8112x1 or 8107x1 \u2022 If 8115 claimed - 8107 & 8112 is not covered in the same visit. 8107/8112 4 x-rays annually per member. Pre-auth required prior to claims submission when quantity is more than 8104, 8112x1 or 8107x1 \u2022 No consultation, 8112x1 or 8107x1 \u2022 If 8115 claimed - 8107 & 8112 is not covered in the same visit.. CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS Limited to extensive dental treatment for children younger than 12 years of age and the removal of impacted third molars only. IN-HOSPITAL TREATMENT treatment for children younger than 7 years of age and the removal of impacted third molars. ",
    "crucialRules": "8101 Full mouth examination, 1 per 365 days per member from date of service. 8104 Examination Not within 4 weeks of 8101, 8104. 8159,8155 Once in every 6 months per member from date of service. 8163 \u2022 Limited to 1st and 2nd permanent molars. \u2022 Limited to once per tooth number per lifetime. 8161 Fluoride treatment (Limited to children older than 5 years and younger than 13 years of age) Once in every 6 months per member from date of service. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre-authorisation. 8115 Panoramic x-ray 473.20 N/A One per member in a 3-year period. ",
    "notes": " Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. Patient written consent needed for procedures not covered .",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedSaver",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com or fax 086 687 1285"
      ],
      "fax": [
        " 086 687 1285"
      ],
      "phone": [
        "'0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " SPECIALISED DENTAL SERVICES: No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars. Clear and well defined (no cone-cutting) radiographs are required to support crown authorisations.\uf0b7 Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations). No theatre or hospital benefit for placement of implants.",
    "preAuth": "Maximum 1 per quadrant more than 4 extractions per visit require pre-auth. Surgical removal of tooth Subject to pre-authorisation. Pre-authorisation and x-rays required for: More than 4 restorations per year (especially anterior teeth). 2 or more, 3 or 4 surface restorations per visit. More than 2 anterior restorations per visit. Posterior restorations to beperformed across the midline on the same visit. 3 or 4 surface restorations on third molar teeth. RCT: Retreatment subject to preauthorisation. 8107/8112 X-RAYS : Pre-auth quantity is more than the following: More than 6 per member per annum . Accompanying tariff code,8104, 8112x1 or 8107x1. No consultation, 8112x1 or 8107x1. If 8115 claimed - 8107 & 8112 is not covered in the same visit. Authorisation Two partial metal frame dentures (an upper or/and a lower jaw) per member. IMPLANTS authorisation. REMOVAL OF IMPACTED TEETH: Once per lifetime. authorisation submission. CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS Limited to the removal of impacted third molars only and DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. IN-HOSPITAL TREATMENT Limited to the removal of impacted third molars. dental treatment for children younger than 7 years is not covered by the Scheme and payable only from the members available savings at time of submission of the claim",
    "crucialRules": "consultation for a specific problem (Motivation required for code 8104 if no covered clinical procedure is charged). EXTRA-ORAL X-RAYS: One per member in a 3-year period from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. One restoration per tooth number within 12 months from date of service. ",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged). Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. Patient written consent needed for procedures not covered .",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medipos",
    "plan": "Option A",
    "administrator": "Medipos",
    "contactInfo": {
      "email": [
        "fax: 0865 661 372"
      ],
      "fax": [],
      "phone": [
        "Tel: 0860 100 078 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "YES",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme exclusions: Mouthguards \u2022 Metal inlays in artificial teeth or attached to metal denture frames and plates. Provisional crowns.",
    "preAuth": "Partial metal frame dentures and associated laboratory costs Benefit limited to once per beneficiary per jaw (frame) every 24 months. Crowns and bridges Limited to once per tooth every 36 months. Implants Pre-authorisation is required. Limited to one implant per tooth site per lifetime. Hospitalisation (general anaesthesia) Pre-authorisation. Dependants under the age of eight years for multiple procedures. Removal or extraction of two or more impacted teeth \u2022 Surgical extraction of teeth in more than one quadrant. conscious sedation in rooms Pre-authorisation required, subject to clinical protocols; All costs for anaesthesia will be paid from the day-to-day benefit for dental procedures performed under conscious sedation",
    "crucialRules": "Two annual consultations per beneficiary. X-rays: Intraoral X-rays: Extraoral. One per beneficiary in a two-year period . Two annual scale and polish treatments per beneficiary; Benefit is subject to clinical protocols Benefit for fissure sealants is limited to individuals younger than 16 years of age. Professionally applied fluoride will be covered for a maximum of two per year. Fillings Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Root canal therapy and extractions Benefit is subject to clinical protocols. Plastic dentures Benefit limited to once per beneficiary per jaw (frame) every 24 months; Benefit is subject to clinical protocols. Benefit is available for denture repairs and denture tooth replacements.",
    "notes": "Hospitalisation and all specialised dentistry procedures must be pre-authorised. Dental benefits are subject to clinical protocols and managed care interventions that may require treatment plans and/or radiographs prior to application for benefit. Scheme exclusions apply to dental benefits",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medipos",
    "plan": "Option B",
    "administrator": "Medipos",
    "contactInfo": {
      "email": [
        "fax: 0865 661 372"
      ],
      "fax": [],
      "phone": [
        "Tel: 0860 100 078 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Mouthguards, Metal inlays in artificial teeth or attached to metal denture frames and plates. The metal base to full dentures and associated laboratory costs. ",
    "preAuth": "Partial metal frame dentures and associated laboratory costs Benefit limited to once per beneficiary per jaw (frame) every 24 months. Crowns and bridges Limited to once per tooth every 36 months. Implants Pre-authorisation is required. Limited to one implant per tooth site per lifetime. Hospitalisation (general anaesthesia) Pre-authorisation. Dependants under the age of eight years for multiple procedures. Removal or extraction of two or more impacted teeth \u2022 Surgical extraction of teeth in more than one quadrant. conscious sedation in rooms Pre-authorisation required, subject to clinical protocols; All costs for anaesthesia will be paid from the day-to-day benefit for dental procedures performed under conscious sedation",
    "crucialRules": "Two annual consultations per beneficiary. X-rays: Intraoral X-rays: Extraoral. One per beneficiary in a two-year period . Two annual scale and polish treatments per beneficiary; Benefit is subject to clinical protocols Benefit for fissure sealants is limited to individuals younger than 16 years of age. Professionally applied fluoride will be covered for a maximum of two per year. Fillings Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Root canal therapy and extractions Benefit is subject to clinical protocols. Plastic dentures Benefit limited to once per beneficiary per jaw (frame) every 24 months; Benefit is subject to clinical protocols. Benefit is available for denture repairs and denture tooth replacements.",
    "notes": "Advanced dentistry Includes metal based dentures, inlays/onlays, surgical periodontal management, crowns and bridges as well as orthodontic treatment. All specialised/advanced dentistry procedures including orthodontic services are subject to prior approval. In-hospital dentistry is subject to prior approval and pre-authorisation. Basic dentistry Includes routine prophylaxis scaling and polishing (cleaning), fluoride application, fillings, non-surgical tooth extraction, root canal treatment and plastic dentures. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medipos",
    "plan": "Option C",
    "administrator": "Medipos",
    "contactInfo": {
      "email": [
        "fax: 086 566 1372 enquiries@medipos.co.za"
      ],
      "fax": [],
      "phone": [
        "Tel: 0860 100 078"
      ]
    },
    "networked": "NO",
    "nonNetworkedPayment": "NO",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants no benefits. Mouthguards, Metal inlays in artificial teeth or attached to metal denture frames and plates. The metal base to full dentures and associated laboratory costs. ",
    "preAuth": "Partial metal frame dentures and associated laboratory costs Benefit limited to once per beneficiary per jaw (frame) every 24 months. Crowns and bridges Limited to once per tooth every 36 months. Hospitalisation (general anaesthesia) Pre-authorisation. Dependants under the age of eight years for multiple procedures. Removal or extraction of two or more impacted teeth \u2022 Surgical extraction of teeth in more than one quadrant. conscious sedation in rooms Pre-authorisation required, subject to clinical protocols; All costs for anaesthesia will be paid from the day-to-day benefit for dental procedures performed under conscious sedation",
    "crucialRules": "Two annual consultations per beneficiary. X-rays: Intraoral X-rays: Extraoral. One per beneficiary in a two-year period . Two annual scale and polish treatments per beneficiary; Benefit is subject to clinical protocols Benefit for fissure sealants is limited to individuals younger than 16 years of age. Professionally applied fluoride will be covered for a maximum of two per year. Fillings Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Root canal therapy and extractions Benefit is subject to clinical protocols. Plastic dentures Benefit limited to once per beneficiary per jaw (frame) every 24 months; Benefit is subject to clinical protocols. Benefit is available for denture repairs and denture tooth replacements.",
    "notes": "Hospitalisation and all specialised dentistry procedures must be pre-authorised. Dental benefits are subject to clinical protocols and managed care interventions that may require treatment plans and/or radiographs prior to application for benefit. Scheme exclusions apply to dental benefits",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIBONUS (550)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Resin bonding for restorations that are charged as a separate procedure to the restoration \u2022 Polishing of restorations. Root canal therapy on primary (milk) teeth \u2022 Root canal therapy on third molars (wisdom teeth) \u2022 Direct and indirect pulp capping procedures. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Diagnostic dentures and associated laboratory costs. Provisional dentures and associated laboratory costs. SPECIALISED DENTISTRY The metal base to full dentures \u2022 High impact acrylic. Crowns and bridges Crowns on third molars \u2022 Crown and bridge procedures where there is no extensive tooth structure loss \u2022 ",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X rays required for multiple fillings. Root Canal Therapy and Extractions. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge Pre-authorisation required. Implants Pre-auth required. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years . General anaesthetic benefit available for the removal of impacted teeth. Pre-authorisation required. Deep Sedation Basic Dentistry (beneficiaries under and over the age of 6)and Impacted Teeth.",
    "crucialRules": "Consultations Once per beneficiary every 6 months. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral Once every 3 years. fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from 5 and younger than 13 years of age, Once per beneficiary every 6 months. ",
    "notes": " Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIPLUS PRIME (551) & MEDIPLUS COMPACT (561)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Resin bonding for restorations that are charged as a separate procedure to the restoration \u2022 Polishing of restorations. Root canal therapy on primary (milk) teeth \u2022 Root canal therapy on third molars (wisdom teeth) \u2022 Direct and indirect pulp capping procedures. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Diagnostic dentures and associated laboratory costs. Provisional dentures and associated laboratory costs. SPECIALISED DENTISTRY The metal base to full dentures \u2022 High impact acrylic. Crowns and bridges Crowns on third molars \u2022 Crown and bridge procedures where there is no extensive tooth structure loss \u2022 ",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X rays required for multiple fillings. Root Canal Therapy and Extractions. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge Pre-authorisation required. Implants Pre-auth required. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years . General anaesthetic benefit available for the removal of impacted teeth. Pre-authorisation required. Deep Sedation Basic Dentistry (beneficiaries under and over the age of 6)and Impacted Teeth.",
    "crucialRules": "Consultations Once per beneficiary every 6 months. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral Once every 3 years. fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from 5 and younger than 13 years of age, Once per beneficiary every 6 months. ",
    "notes": " Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "Medphila",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Partial Chrome Cobalt Frame Dentures : No benefit Crown & Bridge No benefits Implants: No Benefits Orthodontics No Benefits. ",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required for multiple fillings; Multiple fillings: A treatment plan and X-rays may be required for multiple fillings covered at the MDT; payable from a sub-limit of R1 610 per family, limited to and included in the Specialised Dentistry limit of R6 540 per family. Plastic Dentures Pre-authorisation required. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Hospitalisation: Impacted Teeth Pre-authorisation required. Sedation in Dental Rooms: Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. Fillings Granted once per tooth in 720 days. Root Canal Therapy and Extractions:; payable from a sub-limit of R1 610 per family, limited to and included in the Specialised Dentistry Limit of R6 540 per family. ",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required). Oral Surgery (Non-elective surgery only). Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIVALUE PRIME (552) & MEDIVALUE COMPACT (562)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings: to restore teeth damaged due to toothbrush abrasion, attrition, erosion, and fluorosis. Root canal therapy on primary (milk) teeth. Root canal therapy on third molars (wisdom teeth) Direct and indirect pulp capping procedures. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Diagnostic dentures and associated laboratory. Provisional dentures and associated laboratory costs. SPECIALISED DENTISTRY: The metal base to full dentures and the associated laboratory costs. Crowns and bridges on third molars. Crown and bridge procedures for cosmetic reasons and associated laboratory costs. Laboratory fabricated temporary crowns. Provisional crowns and associated laboratory costs. Emergency crowns that are not placed for the immediate protection in tooth injury and Implants on third molars (wisdom teeth)",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X rays required for multiple fillings. Root Canal Therapy and Extractions. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. . Crown & Bridge Pre-authorisation required. Implants Pre-auth required. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years . General anaesthetic benefit available for the removal of impacted teeth. Pre-authorisation required. Deep Sedation Basic Dentistry (beneficiaries under and over the age of 6)and Impacted Teeth.",
    "crucialRules": "Consultations Once per beneficiary every 6 months. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral Once every 3 years. fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from 5 and younger than 13 years of age, Once per beneficiary every 6 months. ",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required).Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDISAVER (683)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings: to restore teeth damaged due to toothbrush abrasion, attrition, erosion, and fluorosis. Root canal therapy on primary (milk) teeth. Root canal therapy on third molars (wisdom teeth) Direct and indirect pulp capping procedures. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Diagnostic dentures and associated laboratory. Provisional dentures and associated laboratory costs. SPECIALISED DENTISTRY: The metal base to full dentures and the associated laboratory costs. Crowns and bridges on third molars. Crown and bridge procedures for cosmetic reasons and associated laboratory costs. Laboratory fabricated temporary crowns. Provisional crowns and associated laboratory costs. Emergency crowns that are not placed for the immediate protection in tooth injury and Implants on third molars (wisdom teeth)",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X rays required for multiple fillings. Root Canal Therapy and Extractions. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. . Crown & Bridge Pre-authorisation required. Implants Pre-auth required. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years . General anaesthetic benefit available for the removal of impacted teeth. Pre-authorisation required. Deep Sedation Basic Dentistry (beneficiaries under and over the age of 6)and Impacted Teeth.",
    "crucialRules": "Consultations Once per beneficiary every 6 months. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral Once every 3 years. fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from 5 and younger than 13 years of age, Once per beneficiary every 6 months. ",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required).Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "PREMIUM PLUS (686) ",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings: to restore teeth damaged due to toothbrush abrasion, attrition, erosion, and fluorosis. Root canal therapy on primary (milk) teeth. Root canal therapy on third molars (wisdom teeth) Direct and indirect pulp capping procedures. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Diagnostic dentures and associated laboratory. Provisional dentures and associated laboratory costs. SPECIALISED DENTISTRY: The metal base to full dentures and the associated laboratory costs. Crowns and bridges on third molars. Crown and bridge procedures for cosmetic reasons and associated laboratory costs. Laboratory fabricated temporary crowns. Provisional crowns and associated laboratory costs. Emergency crowns that are not placed for the immediate protection in tooth injury and Implants on third molars (wisdom teeth)",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X rays required for multiple fillings. Root Canal Therapy and Extractions. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. . Crown & Bridge Pre-authorisation required. Implants Pre-auth required. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years . General anaesthetic benefit available for the removal of impacted teeth. Pre-authorisation required. Deep Sedation Basic Dentistry (beneficiaries under and over the age of 6)and Impacted Teeth.",
    "crucialRules": "Consultations Once per beneficiary every 6 months. X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral Once every 3 years. fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from 5 and younger than 13 years of age, Once per beneficiary every 6 months",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required).Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDICORE (685)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "No",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Hospitalisation: Impacted Teeth No benefit. ",
    "preAuth": "Hospitalisation: Basic Dentistry: General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years for extensive dental treatment. Sedation in Dental : Basic Dentistry (beneficiaries under the age of 6): Anaesthetist and dental accounts payable at the MDT from the OAL. ",
    "crucialRules": "Oral Surgery: Covered at the MDT; payable from the Maxillo-facial limit of R13 610 per family. Maxillo-facial Surgery: Covered at the MDT; the doctor\u2019s account can be covered up to 200% of MDT Payable from the Maxillo-facial limit of R13 610 per family",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Medshield",
    "plan": "Medicurve",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 87 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Root Canal Therapy and Extractions No benefit. Plastic Dentures and Associated Laboratory Costs No benefit . SPECIALISED DENTISTRY No benefit. Hospitalisation: Basic Dentistry No benefit",
    "preAuth": "Hospitalisation: Impacted Teeth* *Pre-authorisation required. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit subject to managed care protocols ONLY for Impacted Teeth:",
    "crucialRules": "8101 One dental examination (code 8101) with a R150 upfront co-payment per beneficiary; the visit in the following year must be scheduled at least 6 months after the visit in the previous year. 8104 One specific consultation (emergency) for pain and sepsis per beneficiary per year. 8107 and/or 8112 Four X-rays in total per beneficiary per year. One Extraoral X-ray (8115) per beneficiary per lifetime. One polish (8155), or one scaling and polishing (8159) per beneficiary per year; the visit in the following year must be scheduled at least 6 months after the visit in the previous year. 8163 One per tooth in a 3-year period for beneficiaries younger than 16 years of age. Benefit for 4 fillings per beneficiary per year Benefit for fillings are granted once per tooth in 720 days . 8201 Extraction of tooth or exposed roots . ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Momentum",
    "plan": "Evolve",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [
        "+27 31 573 4016"
      ],
      "fax": [],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Pre authorisation all specialised dental. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to HealthSaver+open_in_new funds, if available. Basic dentistry: You can use HealthSaver+open_in_new funds, if available.",
    "crucialRules": "Not covered. You will pay out-of-pocket or useHealthSaver+open_in_newfunds, if available.",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Momentum",
    "plan": "Custom",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [
        "+27 31 573 4016"
      ],
      "fax": [],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Pre authorisation all specialised dental. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to HealthSaver+open_in_new funds, if available. Basic dentistry: You can use HealthSaver+open_in_new funds, if available.",
    "crucialRules": "Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to R1 740 co-payment and pre-authorisation Other specialised dentistry: Subject to Momentum HealthSaver+ if available",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Momentum",
    "plan": "Insentive",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [
        "+27 31 573 4016"
      ],
      "fax": [],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Pre authorisation all specialised dental",
    "crucialRules": " Basic dentistry: Subject to Savings. Specialised dentistry: Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to R1 590 co-payment and pre-authorisation. Other specialised dentistry: Subject to Savings or HealthSaver+open_in_new, if available. Dentistry \u2013 basic (such as extractions or fillings) Subject to Savings, if available",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Momentum",
    "plan": "Extender",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [
        "+27 31 573 4016"
      ],
      "fax": [],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Dentistry \u2013 specialised (such as bridges or crowns) R15 500 per beneficiary, R40 400 per family Both in-and out-of-hospital dental specialist accounts accumulate towards the specialised dentistry limit Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to R1 590 co-payment and pre-authorisation",
    "crucialRules": "Dentistry \u2013 basic (such as extractions or fillings) Unlimited . Dentistry \u2013 specialised (such as bridges or crowns). Both in-and out-of-hospital dental specialist accounts accumulate towards the limit. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Momentum",
    "plan": "Summit",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [
        "+27 31 573 4016"
      ],
      "fax": [],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Dentistry \u2013 specialised (such as bridges or crowns) R17 800 per beneficiary, R42 900 per family. Subject to overall annual day-to-day limit of R29 700 per beneficiary. Both in- and out-of-hospital dental specialist accounts accumulate towards the limit Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to pre-authorisation",
    "crucialRules": "Dentistry \u2013 basic (such as extractions or fillings) Subject to overall annual day-to-day limit, Dentistry \u2013 specialised (such as bridges or crowns), Subject to overall. annual day-to-day limit of R28 000 per beneficiary. Both in- and outof-hospital dental specialist accounts accumulate towards the limit. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit. ",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "CLASSIC OPTION AND CLASSIC NETWORK",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered. Replacement of noncarious amalgam with resin will not be covered. Posterior teeth restorations cannot be performed across the midline",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre authorisation. 8937 Surgical removal of erupted tooth Pre-Authorisation required. Pre-authorisation and xrays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 1 Restoration code per tooth number in a 9 month period. 8281 Metal Frame Dentures 1 Frame in 5 years per member. Crown and Bridges PRE AUTH. Hospitalisation; Pre-authorisation required apply Children under the age of 8 with extensive conservative (basic) dental treatment, limited to 1 admission per member per 24 months. Removal of impacted wisdom teeth but must be pre-authorised .",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Maximum of 8 can be charged per member, 2 per quadrant on members younger than 16 years. Code 8107, 8112 and 8121 cannot be charged more than 7 times (per year).Root Canal Only covered on permanent teeth. One set of plastic dentures, full or partial (an upper and a lower) per beneficiary in a 4 year period for members. Implants \u2022 Placement covered in dental chair only. ",
    "notes": " 8115 Extra-oral radiograph \u2013 panoramic Maximum 2 Panoramic radiographs per member per treatment plan \u2013 per 24 months (six-month time lapse applies). 8169 Occlusal guard Pre-authorisation required. Once every 24 months. 8269 Repair of denture . 8273 Impression to repair denture , Rebase complete or partial denture, Remodel complete or partial denture, Reline, Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after new denture) 365 days per member. Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "Optimum",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered. Replacement of noncarious amalgam with resin will not be covered. Posterior teeth restorations cannot be performed across the midline",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require pre authorisation. 8937 Surgical removal of erupted tooth Pre-authorisation required. Pre-authorisation and xrays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 1 Restoration code per tooth number in a 9 month period. Root Canal Codes \u2013 Pre-authorisation needed. Dentures Codes \u2013 Pre-authorisation One set of plastic dentures, full or partial (an upper and a lower) per beneficiary . Hospitalisation: Pre-authorisation required. Children under the age of 8 with extensive conservative (basic) dental treatment, limited to 1 admission per member per 24 months. \u2022 Removal of impacted wisdom teeth but must be pre-authorised. ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. Code 8107, 8112 and 8121 cannot be charged more than 7 times (per year). 8115 Maximum 2 Panoramic radiograph per member per treatment plan \u2013 per 24 months (six-month time lapse applies). 8163 Fissure sealant Maximum of 8 can be charged per member, 2 per quadrant on members younger than 16 years. 8281 Metal Frame Dentures 1 Frame in 5 years per member. Crown and Bridges \u2022 Pre-authorisation required. ",
    "notes": "8269 Repair of denture, 8273 Impression to repair denture , Rebase complete or partial denture, Remodel complete or partial denture, Reline, Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after new denture) 365 days per member. Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "Custom and Essential Options",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff.",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth. 8259 Rebase of denture (laboratory) Rebase complete or partial denture (once a calendar year per member). 8261 Remodel of denture Rebase complete or partial denture (once a calendar year per member. Reline complete or partial denture (once a calendar year per member) Repair denture Twice per calendar year per member",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "NATIONAL HEALTHCARE GROUP (NHG)",
    "plan": "MEDICLUB ELITE PLAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "861 445 566"
      ]
    },
    "networked": "NO",
    "nonNetworkedPayment": "NO",
    "balancedBilling": "NO",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Motivation and records required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) Benefit for fillings is granted once per tooth in 9 months. 8132 Pulpectomy (pain relief) Not covered on primary teeth ",
    "crucialRules": " 8101 Two consultations per dependant per year (once every 6 months). 8104 Specific consultation One specific consultation for pain and sepsis per dependant per year; not within 4 weeks of 8101. 8107 and/or 8112 Maximum of 2 X-rays films per visit per dependant . Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159). 8161 Two treatments per year for dependants younger than 12 years of age (once every 6 months) . 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "NBCPSS",
    "plan": "NBCPSS",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Panorex No specialised dentistry",
    "preAuth": "Surgical removal of tooth: Surgical removal is only covered when authorised. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 8935 Treatment of septic socket More than 1 treatment per visit requires Authorisation.",
    "crucialRules": "One full mouth examination per covered period. Limited to 180 days apart from date of service. 8159 & 8155 1 per covered period. \u2022 Limited to 180 days apart from date of service. Limited to 3 extractions per member per covered period. \u2022 Maximum of 1 X 8201 per quadrant per visit. Limited to 3 restorations per member per covered period. \u2022 1 Restorations code per tooth number in a 12-month period.",
    "notes": " 1. Very basic option. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "NBCRFLI",
    "plan": "NBCRFLI",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Specialised dental. No Restorations. No Preventative care. No Xrays.",
    "preAuth": "8937 Surgical removal of tooth is only covered when authorised with an intraoral radiograph. . Treatment of septic socket. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require preauth. ",
    "crucialRules": "One full mouth examination per annum. Limited to 3 extractions per annum. Limited to 3 fillings per annum. 1 Restorations code per tooth number in a 12-month period.",
    "notes": "Only Emergency Treatment, Infection Control, Extraction, Surgical Removals. 1. Very basic option. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Netcare",
    "plan": "Savings",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "member@netcaremedicalscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 638 633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Panorex every 3 years.",
    "preAuth": "Phone only for reference nr. You do not need authorisation for dentistry out-of-hospital however we recommend that you call us before getting any dental treatment to confirm your benefits as dental managed care protocols and limits apply.",
    "crucialRules": "Combined in- and out-of-hospital dentistry limit . Preferred Provider use recommended to minimise co-payments. ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "NHG MediClub Elite",
    "plan": "NHG MediClub Elite",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Fillings Motivation and records required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) Benefit for fillings is granted once per tooth in 9 months",
    "crucialRules": "8101 Full mouth examination, Two consultations per dependant per year (once every 6 months). 8104 Specific consultation One specific consultation for pain and sepsis per dependant per year; not within 4 weeks of 8101. 8107 and/or 8112 Maximum of 2 X-rays films per visit per member. Code 815 & 8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots",
    "notes": "Benefits payable on the NHG MediClub Elite Plan is subject to the use of a Network Service Provider on the DENIS Dental Network. There will be no benefit for out-of-network visits and treatment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Old Mutual Health Solutions (OMHS)",
    "plan": " COMPREHENSIVE ADVANCED OMPREHENSIVE PLUS COMPREHENSIVE STANDARD PLAN PRIMARY STANDARD PRIMARY STANDARD PLUS GOLDEN HOUR PRIMARY STANDARD PLUS HOSPITAL PLAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "862 445 566"
      ]
    },
    "networked": "NO",
    "nonNetworkedPayment": "NO",
    "balancedBilling": "NO",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Motivation and records required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) Benefit for fillings is granted once per tooth in 9 months",
    "crucialRules": "8101 Full mouth examination, Two consultations per dependant per year (once every 6 months). 8104 Specific consultation One specific consultation for pain and sepsis per dependant per year; not within 4 weeks of 8101. 8107 and/or 8112 Maximum of 2 X-rays films per visit per dependant . Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159). 8161 Fluoride treatment Two treatments per year for dependants younger than 12 years of age (once every 6 months) 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots . ",
    "notes": "8132 Pulpectomy (pain relief) Not covered on primary teeth",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Old Mutual Staff ",
    "plan": "Network & network select",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "enquiries@omsmaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860100076"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment . 80% of cost payable by the scheme and includes professional and laboratory fees. 20% member liability",
    "crucialRules": "Limited to 1 consultation per beneficiary. One set of dentures every 24 months per beneficiary. A 20% co- payment applies. Restorations /Composite Fillings Preauthorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Extractions re-authorisation required for 4 or more extractions per annum per beneficiary. Teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Old Mutual Staff ",
    "plan": "Traditional & Traditional select",
    "administrator": "Old Mutual",
    "contactInfo": {
      "email": [
        "enquiries@omsmaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860100076"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings: \u2022 Exclusions: Fillings to restore teeth due to toothbrush abrasion; attrition; erosion Resin bonding that is charged separately Polishing of restorations Gold foil restorations Ozone Therapy. ",
    "preAuth": "\u2022 Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. Extractions. \u2022 1 set of plastic dentures (an upper and a lower) per beneficiary per three years \u2022 Partial dentures consisting of more than 9 teeth require clinical motivation. mmediate dentures: one tissue conditioning and one reline/rebase allowed. \u2022 2 metal dentures (2 frames for upper and lower jaws) allowed per beneficiary per 4 year cycle \u2022 Partial metal dentures of more than 9 teeth require motivation as to why a full denture cannot be made \u2022 All implant supported dentures even though they are modified plastic dentures. Crowns; Bridges Preauthorisation required . Implants and associated costs. Pre-authorisation required. \u2022 Beneficiary must be > 18 years old \u2022 Motivation required for beneficiaries > 70 years old. Hospitalisation (General anaesthetic) Children younger than 8 years on the Traditional, Traditional Plus (including Select) and Savings Plans Removal of impacted wisdom teeth (if the beneficiary is older than 30 years, a clinical motivation will be required)",
    "crucialRules": "Consultations \u2022 2 annual checks ups per beneficiary per year. X Rays: Intraoral / Extraoral \u2022 Subject to clinical protocols. Scaling and polishing \u2013 2 per annum with routine check-ups per beneficiary. \u2022 Oral hygiene instructions \u2013 1 per annum per beneficiary. issure sealant \u2022 Only in beneficiaries < 21 years old. Root canal therapy One root canal therapy allowed per tooth per lifetime ",
    "notes": "Occlusal guards \u2022 Only allowed in cases for preservation of tooth structure and maintenance of the periodontium",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "PG Group",
    "plan": "PG GROUP",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "pgenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860104939"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Fillings to restore teeth due to toothbrush abrasion; attrition; erosion Resin bonding that is charged separately Polishing of restorations. Gold foil restorations Ozone Therapy. Crown & Bridge. Crowns on third molars (wisdom teeth. Crown and bridge procedures for cosmetic reasons and associated laboratory costs. Laboratory fabricated temporary crowns. Occlusal rehabilitations and associated laboratory costs. Provisional crowns and associated laboratory costs. Porcelain veneers and inlays, and associated laboratory costs. Emergency crowns that are not placed for the immediate protection in tooth injury and associated laboratory costs The cost of gold, precious metal, semi-precious metal and platinum foil. Laboratory delivery fees. ",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required Three crowns per family per year. Pre-authorisation required Two implants per beneficiary in a 5-year period . General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment. General anaesthetic benefit available for the removal of impacted teeth.Pre-authorisation required. Pre-authorisation required",
    "crucialRules": "Two check-ups per beneficiary per year (once every 6 months). X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period . Two annual scale and polish treatments per beneficiary (once every 6 months). Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age. Root Canal Therapy and Extractions Benefit subject to managed care protocols . ",
    "notes": ". Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). \u2022 Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Pick n pay",
    "plan": "Primary (Carecross)",
    "administrator": "momentum",
    "contactInfo": {
      "email": [
        "picknpay@mhg.co.za"
      ],
      "fax": [],
      "phone": [
        "0800004389"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff.",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth.",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Pick n pay",
    "plan": "Plus",
    "administrator": "momentum",
    "contactInfo": {
      "email": [
        "picknpay@mhg.co.za"
      ],
      "fax": [],
      "phone": [
        "0800004389"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Gum guards for sport purposes, gold in dentures and the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges and bleaching of teeth",
    "preAuth": " Multiple fillings: A treatment plan and X-rays may be required. Plastic Dentures Pre-authorisation required. Partial Chrome Cobalt Frame Dentures. Pre-authorisation required. Crown & Bridge . Pre-authorisation required. Maxillofacial surgery: Subject to pre-authorisation.",
    "crucialRules": "Advanced dentistry and dental implants Includes dentures, inlays/onlays, periodontal surgery, crowns and bridges as well as orthodontic treatment . All dentistry benefits are subject to the Scheme`s managed care protocols and benefits. Conservative dentistry \u2013 fillings, extractions, X-rays and prophylaxis: R2 900 per family per year, payable from the insured benefit, thereafter subject to MSA balance",
    "notes": " Patient written consent needed for procedures not covered. Conservative dentistry \u2013 fillings, extractions, X-rays and prophylaxis: R2 600 per family per year, payable from the insured benefit, thereafter subject to MSA balance.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Platinum health",
    "plan": "Plat Comprehensive",
    "administrator": "Platinum health",
    "contactInfo": {
      "email": [
        "hospitalconfirmations@platinumhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0800006942"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Gum guards for sport purposes, gold in dentures and the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges and bleaching of teeth",
    "preAuth": "Hospital; patients under the age of eight years and impacted third molars. Pre authorisation. Dentures shall be limited to one set every three years from anniversary of claiming PB, subject to benefit limit. Pre authorisation for\u2022 Crown and Bridge work, \u2022 Metal Dentures. ",
    "crucialRules": "A 15% co-payment of the benefit limit shall apply in respect of the repair and replacement of dentures. ",
    "notes": "General anaesthetic and hospitalisation for conservative dental work excluded, except in the case of trauma, patients under the age of eight years and impacted third molars. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Platinum health",
    "plan": "Plat Freedom",
    "administrator": "Platinum health",
    "contactInfo": {
      "email": [
        "086 233 2406 "
      ],
      "fax": [],
      "phone": [
        "0800006942"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Gum guards for sport purposes, gold in dentures and the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges and bleaching of teeth",
    "preAuth": " Pre authorisation for basic dentistry performed inhospital for children under eight (8) and for removal of impacted wisdom teeth. Authorisation required for advanced dentistry, subject to clinical protocol approval",
    "crucialRules": "Advanced: Oral surgery, metal base dentures, inlays, crowns, bridges, study models, orthodontics, periodontics, prosthodontics, osseointegrated implants, orthognathic surgery and dental technician fees.100% of the lower of cost or Scheme Rate . Basic: Includes plastic dentures and basic dentistry performed in-hospital for children under eight (8) and for removal of impacted wisdom teeth.",
    "notes": "R17 391 per member family Dentures shall be limited to one set every three years",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Platinum health",
    "plan": "Platcap ",
    "administrator": "Platinum health",
    "contactInfo": {
      "email": [
        "086 233 2406 "
      ],
      "fax": [],
      "phone": [
        "0800006942"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Dentures only One set of plastic dentures PB. Dentures shall be limited to one set of plastic dentures per 3 consecutive years PB, applicable over age of 21 years. (20% co-payment applies). General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years . General anaesthetic benefit available for the removal of impacted teeth. Pre-authorisation required. Deep Sedation Basic Dentistry (beneficiaries under and over the age of 7)and Impacted Teeth.",
    "crucialRules": "Conservative Dentistry. One consultation PB per annum, with exception of extractions which are unlimited. \u2022 One preventative treatment PB per annum for cleaning, fillings and x-rays with exception of extractions which are unlimited. Emergency Dentistry: One-episode PB for pain and sepsis only for in-or-out of network emergency dentistry per annum",
    "notes": "\u2022 Dentures shall be limited to one set of plastic dentures per 3 consecutive years PB, applicable over age of 21 years. (20% co-payment applies)",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Polmed",
    "plan": "Marine",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "polmedhospitalauthorisations@denis.co.za",
        "polmedcustomerservice@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "Tel:\t0860 765 633 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "NO",
    "surgicalExtractions": "Yes",
    "exclusions": "NO BENEFITS FOR IMPLANTS",
    "preAuth": "Dentistry (specialised) Pre-authorisation required. Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial metal frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge. Pre-authorisation required .Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . surgical removal impacted tooth pre auth.",
    "crucialRules": "Two check-ups per beneficiary per year (once every 6 months). X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period . Two annual scale and polish treatments per beneficiary (once every 6 months). Benefit for fluoride: 8161 Limited to beneficiaries from age 5 and younger than 13 years of age, one per beneficiary in 12 months. Fissure sealant age 5-25 limit 4 per beneficiary. Root Canal Therapy and Extractions Benefit subject to managed care protocols . ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Polmed",
    "plan": "Aquarium",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "polmedhospitalauthorisations@denis.co.za",
        "polmedcustomerservice@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "Tel:\t0860 765 633 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "Yes",
    "exclusions": "NO BENEFITS FOR IMPLANTS, CROWNS & BRIDGES, METAL PARTIAL DENTURE",
    "preAuth": "Dentistry (specialised) Pre-authorisation required. Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . surgical removal impacted tooth pre auth.",
    "crucialRules": "Two check-ups per beneficiary per year (once every 6 months). X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period . Two annual scale and polish treatments per beneficiary (once every 6 months). Benefit for fluoride: 8161 Limited to beneficiaries from age 5 and younger than 13 years of age, one per beneficiary in 12 months. Fissure sealant age 5-25 limit 4 per beneficiary. Root Canal Therapy and Extractions Benefit subject to managed care protocols . ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Profmed",
    "plan": "PROPINNACLE SAVVY PROSECURE PLUS SAVVY PROSECURE SAVVY PROACTIVE PLUS SAVVY ",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Hospitalisation: -Permanent tooth impaction removals -Extensive conservative dental treatment only for children younger than 8 years (24-month benefit). Plastic DenturesPre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Preauthorisation is required for: a) Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and bridgework, functional orthognathic surgery, orthodontics up to 18 years of age ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. Codes 8159, & 8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service.. 1 Restoration code per tooth number in a 9 month period. All extractions of tooth or exposed tooth roots should be charged as 8201 (Extraction of tooth or exposed roots). All surgical removal of residual roots should be charged as 8213 (Surgical removal of residual tooth roots). All surgical removal of impacted teeth should be charged as 8941 (Surgical removal of impacted tooth).",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Profmed",
    "plan": "PROSELECT (NETWORK OPTION) PROSELECT & PROSELECT SAVVY",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "",
    "preAuth": " Permanent tooth impaction removals- Extensive conser vative dental treatment only for children younger than 8 years (24- month benefit) (Subject to pre-authorisation, protocols and management). Conservative dentistry (includes Restorations, extractions, root canal treatment, ",
    "crucialRules": "R718 per beneficiary Maximum R1 848 per family",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Profmed",
    "plan": "PROPINNACLE PROSECURE PLUS PROSECURE PROACTIVE PLUS ",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Functional orthognathic surgery. Includes all costs related to the admission and procedure, e.g. all medical practitioner fees, hospitalisation, etc. (Subject to pre-authorisation) No benefit",
    "preAuth": "Hospitalisation: -Permanent tooth impaction removals -Extensive conservative dental treatment only for children younger than 8 years (24-month benefit). Plastic DenturesPre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Preauthorisation is required for: a) Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and bridgework, functional orthognathic surgery, orthodontics up to 18 years of age ",
    "crucialRules": "8102 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. Codes 8159, & 8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service.. 1 Restoration code per tooth number in a 9 month period. All extractions of tooth or exposed tooth roots should be charged as 8201 (Extraction of tooth or exposed roots). All surgical removal of residual roots should be charged as 8213 (Surgical removal of residual tooth roots). All surgical removal of impacted teeth should be charged as 8941 (Surgical removal of impacted tooth).",
    "notes": "Not subject to day-to-day limit",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Rand water",
    "plan": "Option B Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Emergency root canal/pulp removal (pulpectomy) charged on the same day as complete therapy. Diagnostic dentures Metal base to full dentures. Crowns used to restore teeth for cosmetic reasons Crowns where the tooth has been recently restored to function Composite or porcelain veneers Laboratory fabricated crowns are not covered on primary teeth or third molars (wisdom teeth) Crowning of teeth involving failed R.C.T Temporary /provisional and emergency crowns including lab costs.",
    "preAuth": " 8107/8112 Only 7 per member per year. More requires motivation. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 5 extractions per visit require preauthorisation. 8169 Occlusal guard Pre-authorisation required. Once every 24 months. Pre-authorisation and xrays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 1 Restoration code per tooth number in a 9 month period. Conscious sedation up to 16 years of age every 24 months. Pre-Authorisation is required for hospitalisation related to dental trauma cases, removal of impacted teeth as well as children under 7 years of age. ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 163 Fissure sealant Maximum of 8 can be charged per member, 2 per quadrant on members younger than 16 years. Root Canal Treatment (payable from risk pool) ",
    "notes": "8115 Panoramic x-ray Maximum of 1 per year. ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Rand water",
    "plan": "OPTION A",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439612"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Emergency root canal/pulp removal (pulpectomy) charged on the same day as complete therapy. Diagnostic dentures Metal base to full dentures. Crowns used to restore teeth for cosmetic reasons Crowns where the tooth has been recently restored to function Composite or porcelain veneers Laboratory fabricated crowns are not covered on primary teeth or third molars (wisdom teeth) Crowning of teeth involving failed R.C.T Temporary /provisional and emergency crowns including lab costs.",
    "preAuth": "8107 / 8112 Only 7 per member per year. More requires motivation. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 5 extractions per visit require preauthorisation. 8169 Occlusal guard Pre-authorisation required. Once every 24 months. Pre-authorisation and xrays required for \u2022 More than 4 restorations per member in a year require pre-authorisation and motivation. \u2022 Once per tooth number in 365 days from date of service. Plastic Dentures (pre-authorisation required) Limited to one set per beneficiary once every 4 years. Limited to 1 crown per tooth number every 5 years. Implants and Augmentation (pre-authorisation required). Pre-Authorisation is required for hospitalisation related to dental trauma cases, removal of impacted teeth as well as children under 7 years of age. ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8115 Panoramic x-ray Maximum of 1 per year. 8163 Limited to permanent molars and pre-molars. Not within 2 years of previous treatment. 8269 Repair of denture . 8273 Impression to repair denture , Rebase complete or partial denture, Remodel complete or partial denture, Reline, Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after new denture) 365 days per member.",
    "notes": "Panoramic x-ray (Extra-oral) Maximum of 1 per year.In the case of fillings on posterior teeth (molars and pre-molars) the Dental Risk Company tariff for amalgam fillings will apply, regardless of the material used.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "REEF BUS SECTOR",
    "plan": "REEF BUS SECTOR",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087943961"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "Yes",
    "exclusions": "NO BENEFITS FOR SPECIALIZED DENTISTRY",
    "preAuth": "Limited to 3 restorations per member per covered period. \u2022 1 Restorations code per tooth number in a 12-month period. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 8937 Surgical removal is only covered when authorised. 8935 Treatment of septic socket More than 1 treatment per visit requires Authorisation ",
    "crucialRules": "One full mouth examination per covered period. Limited to 180 days apart from date of service. 8107 / 8112 2 per member per covered period. 8159 &8155 1 per covered period. \u2022 Limited to 180 days apart from date of service. Limited to 3 extractions per member per covered period. \u2022 Maximum of 1 X 8201 per quadrant per visit. ",
    "notes": "Patient to consent to such - form to be completed and signed by patient. 1. Very basic option.Authorisation is not a guarantee of payment but a clinical indication as at time and date that the authorisation was processed.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Remedi",
    "plan": "Standard",
    "administrator": "Drc Discovery",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No specialised dentistry. No panorex.",
    "preAuth": "Consultations (Motivation required for code 8104 if no covered clinical procedure is charged). Extraction Codes 1 per quadrant per member per visit, per year, more than 4 require preauthorisation. Pre-authorisation required for more than 2 restorations per visit (supporting radiographs required).",
    "crucialRules": "8101 Once per 6 months per member from date of service. 8104 Not within 4 weeks of 8101 (8102 not covered). (Motivation required for code 8104 if charged). Maximum of 2 x-rays per visit per member. \u2022 Maximum quantity of 7 per year. 8159,8155,8161,8162 Once per 6 months per member from date of service. ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Remedi",
    "plan": "Comprehensive",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860116116"
      ],
      "fax": [],
      "phone": [
        "0860116116"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Benefit for re-treatment of a tooth: Subject to managed care protocols Multiple fillings: A treatment plan and Xrays may be required for multiple fillings.Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Conservative dentistry under anaesthesia for patients younger than seven years. ",
    "crucialRules": "Consultations Two check-ups per beneficiary per year (once every 6 months). Code 8159 & 8155 Once per 6 months per member from date of service. . 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Root Canal Therapy and Extractions Benefit subject to managed care protocols.",
    "notes": "The hospital account and anaesthesiologists\u2019 account for patients under the age of seven years and for wisdom-teeth removal. The dentist\u2019s account is paid from the Insured Out-of-Hospital Benefit and thereafter from the Personal Medical Savings Account. Authorisation is needed for the above.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Remedi",
    "plan": "Classic",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860116116"
      ],
      "fax": [],
      "phone": [
        "860,116,116"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "There is no benefit for conservative dentistry in hospital, the anaesthesiologists\u2019 account and the hospital account will be paid by the member. The dentist account is paid from the Insured Out-of-Hospital Benefit",
    "preAuth": "Benefit for re-treatment of a tooth: Subject to managed care protocols Multiple fillings: A treatment plan and Xrays may be required for multiple fillings.Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Conservative dentistry under anaesthesia for patients younger than seven years. ",
    "crucialRules": "Consultations Two check-ups per beneficiary per year (once every 6 months). Code 8159 & 8155 Once per 6 months per member from date of service. . 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions Benefit subject to managed care protocols. Root Canal Therapy and Extractions Benefit subject to managed care protocols.",
    "notes": "The hospital account and anaesthesiologists\u2019 account for patients under the age of seven years and for wisdom-teeth removal. The dentist\u2019s account is paid from the Insured Out-of-Hospital Benefit and thereafter from the Personal Medical Savings Account. Authorisation is needed for the above.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Sab breweries",
    "plan": "Comprehensive Option",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations \u2022 Bone and tissue regeneration procedures \u2022 Crowns and bridges for cosmetic reasons and associated laboratory costs \u2022 Enamel micro abrasion \u2022 Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil \u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": " A treatment plan and Xrays may be required for multiple fillings.Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period .",
    "crucialRules": "For basic dentistry 80% of the lower of cost or Scheme Rate. Consultations Two check-ups per beneficiary per year (once every 6 months) . Code 8159 & 8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age. Root Canal Therapy and Extractions Benefit subject to managed care protocols.",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Sab breweries",
    "plan": "Essential",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445567"
      ],
      "fax": [],
      "phone": [
        "0860445567"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Advanced dentistry + Implants No Benefit.",
    "preAuth": " A treatment plan and Xrays may be required for multiple fillings.Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period .",
    "crucialRules": "For basic dentistry 80% of the lower of cost or Scheme Rate Consultations Two check-ups per beneficiary per year (once every 6 months). Code 8159 & 8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age ",
    "notes": "80% of the lower of cost or Scheme Rate M = R3 890 M + 1 = R6 419 M + 2 = R7 551 M + 3 = R8 663",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Samwumed",
    "plan": "Option A",
    "administrator": "DENIS",
    "contactInfo": {
      "email": [
        "denis"
      ],
      "fax": [],
      "phone": [
        "0860104117"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "YES",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefit for advanced dentistry on Option A. Crown & Bridge No benefit . Implants No benefit ",
    "preAuth": " Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Pre-authorisation required. General anaesthetic benefit available for children under the age of 7 years for extensive dental treatment. Deep Sedation in the Dental Rooms Benefit limited to extensive dental treatment and removal of impacted teeth. ",
    "crucialRules": "Two check-ups per beneficiary per year (once every 6 months). X-rays: Intraoral Benefit subject to managed care protocols . X-rays: Extraoral One per beneficiary in a 3-year period . Benefit for scale and polish: Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 16 years of age . Extractions Benefit subject to managed care protocols. Root Canal Therapy No Benefit . Benefit ONLY available for the treatment of pain and sepsis: \u2022 Code 8132 \u2022 Code 8307 (Primary teeth only) One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period Pre-authorisation required.",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no preauthorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Samwumed",
    "plan": "Option B",
    "administrator": "DENIS",
    "contactInfo": {
      "email": [
        "denis"
      ],
      "fax": [],
      "phone": [
        "0860104117"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "NO",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and Associated Laboratory Costs No benefit",
    "preAuth": " Benefit for fillings: Granted once per tooth in 720 days Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Pre-authorisation required. General anaesthetic benefit available for children under the age of 7 years for extensive dental treatment. Deep Sedation in the Dental Rooms Benefit limited to extensive dental treatment and removal of impacted teeth. Benefit for crowns will be granted once per tooth in a 5-year period Pre-authorisation required. General anaesthetic benefit available for children under the age of 7 years for extensive dental treatment. \u2022 General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Benefit limited to extensive dental treatment and removal of impacted teeth. ",
    "crucialRules": "Two check-ups per beneficiary per year (once every 6 months). X-rays: Intraoral Benefit subject to managed care protocols . X-rays: Extraoral One per beneficiary in a 3-year period . Benefit for scale and polish: Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 16 years of age . Extractions Benefit subject to managed care protocols. Root Canal Therapy Benefit subject to managed care protocols. One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period Pre-authorisation required.",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no preauthorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Sasolmed",
    "plan": "sasolmed",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "fax: 0860 665 357 email: dental@sasolmed.co.za (out of hospital) email: authorisations@sasolmed.co.za (in hospital)"
      ],
      "fax": [],
      "phone": [
        "0860002134"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Emergency root canal/pulp removal (pulpectomy) charged on the same day as complete therapy. Diagnostic dentures Metal base to full dentures. Crowns used to restore teeth for cosmetic reasons Crowns where the tooth has been recently restored to function Composite or porcelain veneers Laboratory fabricated crowns are not covered on primary teeth or third molars (wisdom teeth) Crowning of teeth involving failed R.C.T Temporary /provisional and emergency crowns including lab costs.",
    "preAuth": "Benefits only available for: - children under the age of eight years, and - bony impaction of the third molars. All general anaesthesia and conscious sedation subject to pre-authorisation. Benefit for inlays, crowns, bridges, mounted study models, metal-base partial and complete dentures.",
    "crucialRules": "1 Restoration code per tooth number in a 365 days period. Panorex every 3 years. Panorex once every 3 years. Consultations Two check-ups per beneficiary per year (once every 6 months). Code 8159 & 8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age.",
    "notes": "1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Sisonke",
    "plan": "Diversity and Pride Options",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff.",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth. 8259 Rebase of denture (laboratory) Rebase complete or partial denture (once a calendar year per member). 8261 Remodel of denture Rebase complete or partial denture (once a calendar year per member. Reline complete or partial denture (once a calendar year per member) Repair denture Twice per calendar year per member",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Sisonke",
    "plan": "Heritage",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "info@sisonkehealth.co.za"
      ],
      "fax": [],
      "phone": [
        ",0860 104 012"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "YES",
    "dentures": "YES",
    "crownsOrBridges": "YES",
    "implants": "YES",
    "surgicalExtractions": "YES",
    "exclusions": "Emergency root canal/pulp removal (pulpectomy) charged on the same day as complete therapy. Diagnostic dentures Metal base to full dentures. Crowns used to restore teeth for cosmetic reasons Crowns where the tooth has been recently restored to function Composite or porcelain veneers Laboratory fabricated crowns are not covered on primary teeth or third molars (wisdom teeth) Crowning of teeth involving failed R.C.T Temporary /provisional and emergency crowns including lab costs.",
    "preAuth": "Specialised dentistry limited to R8 052 per beneficiary, up to a maximum of R16 109 per member family",
    "crucialRules": "In Hospital Dentistry Covered to R 13 508 per Beneficiary. Scheme Rates apply",
    "notes": "Limit General: Unlimited at preferred provider at Scheme rates \u2022 All dentists in 20km radius of Medical centre - Non -pp: 20% co-payment will apply at non-preferred provider. \u2022 All dentists outside of 20km radius of Medical Centre : Unlimited at preferred provider at Scheme rates \u2022 Dentures (every 3 years): R6 035 PB \u2022 Specialised: R 7 669 PB to max of R15 342 PMF",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "ESSENTIAL COPPER ",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        " 086 000 2121"
      ],
      "fax": [],
      "phone": [
        "0861 033 647 Email: customercare@denis.co.za"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "X-rays: Extraoral No Benefits. No benefit for root canal therapy. No Benefits for specialised dentistry",
    "preAuth": "Dentures limited to R6 616per person, every 3 years",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 365 days. ",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "VALUE PLATINUM AND VALUE PLATINUM CORE",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth. \uf0b7 No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48). \uf0b7 No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and preauthorised. \uf0b7 No Benefit for Laboratory fabricated crowns on primary teeth. \uf0b7 High impact acrylic. \uf0b7 Restorations cannot be claimed on the same tooth number and service date as tariff 8132, only adequately motivated cases will be considered. \uf0b7 Restorations performed on the same tooth number within 9 months of each other will not be covered, unless motivated and pre-authorised. \uf0b7 Full mouth examinations must be performed a 180 days apart.",
    "preAuth": "8169 Occlusal guard :Pre-authorisation required. Once every 24 months. \u2022 Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require preauthorisation. 8937 Surgical removal of tooth More than 1 removal per visit requires Authorisation. Pre-authorisation and xrays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 1 Restoration code per tooth number in a 9 month period. 2 Partial frames (an upper and lower) per beneficiary . Limited to 1 crown per family per annum. 1 Implant per beneficiary per annum over a period of 5 years. IN Hospital General anaesthetic benefits are available for: Extensive dental treatment for children under 7 years of age and 3 or more teeth involved. Limited to once per 365 days per beneficiary. Removal of symptomatic impacted wisdom teeth. Conscious sedation and extensive dental treatment are limited to children up to the age of 16.",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Maximum of 4 can be charged per member per annum. Root Canal Only covered on permanent teeth. 1 Set of plastic dentures, full or partial (an upper and a lower) per beneficiary ",
    "notes": " 1. Very basic option 2. Lower rate applies to non network provider 3. Patient written consent needed for procedures not covered",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "ACCESS SAVER",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "yes",
    "implants": "No",
    "surgicalExtractions": "yes",
    "exclusions": " Will not be covered: \uf0b7 Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis \uf0b7 Replacement of non-carious amalgam with resin \uf0b7 Restorations on non-functional wisdom teeth. \uf0b7 The subsequent restoration will only be authorised once an emailed or scanned x-ray of the completed root canal is received. no specialised dentistry.",
    "preAuth": ". \u2022 More than 4 extractions per visit require pre-authorisation. 8937 Surgical removal of tooth and roots More than 1 removal per visit requires authorisation. 8220 Suture material Applicable with surgical procedures. More than 4 restorations. \u2022 More than two: 3 or 4 surface restorations per visit. \u2022 More than 2 anterior restorations per visit. \u2022 Multiple restorations on an anterior tooth on the same service date. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. \u2022 Deviations will only be covered if pre-authorised. \u2022 Any 3 or 4 surface restorations on wisdom teeth. 1 Restoration code per tooth number in a 9-month period. One set of dentures per member per 4 years for members older than 16 years. Conscious sedation and extensive dental treatment are limited to children up to the age of 16 years. Crowns & Bridgework, Metal Frame Dentures, Orthodontics (up to the age of 21 years), removal of impacted wisdom teeth, non-surgical wisdom teeth and non surgical periodontics are covered from available MSA (Medical Savings account). Removal of symptomatic impacted wisdom teeth. \u2022 Dental treatment rendered to special needs patients in-hospital (pre-authorisation by DRC",
    "crucialRules": " Emergency root canal treatment (8132 Cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date) Not covered on primary teeth. 1 Restoration code per tooth number in a 9 month period. The subsequent restoration will only be authorised once an emailed or scanned x-ray of the completed root canal is received. 8104 Examination Not within 4 weeks of 8101, 8102, 8104. (Motivation required for code 8104 if no covered clinical procedure is charged). Diagnostic Codes: Pre-authorisation and motivation required prior to claims submission when quantity is more than the following:\u2022 With 8101,8112x2 and 8107x1 \u2022 With 8101, 8107x3 \u2022 With 8104, 8112x1 or 8107x1 \u2022 No consult 8112x1 or 8107x1. Maximum of 1 X 8201 per quadrant per visit",
    "notes": "Access Option \u2013 Conservative dentistry payable from the Risk Pool. All clinically valid specialised dental treatment covered from MSA (Medical Savings account) including 1 set of plastic denture per member every 4 years. Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "TITANIUM EXECUTIVE",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and x-rays received \uf0b7 Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered \uf0b7 Replacement of noncarious amalgam restored tooth with a resin will not be covered. Posterior teeth restorations cannot be performed across the midline. Inlay/onlay or \u00be crowns not covered \uf0b7 Crowns on nonfunctional 3rd molars are not covered.",
    "preAuth": "8169 Occlusal guard Pre-authorisation required. Once every 24 months. Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require preauthorisation. 8937 Surgical removal of tooth 775.20 N/A More than 1 removal per visit requires authorisation. Pre-authorisation and x-rays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x-rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. \u2022 Deviations will only be covered if pre-authorised. 1 Restoration code per tooth number in a 9-month period. 2 Partial frames (an upper and lower) per beneficiary in a 5-year period, limited to 2 per family per annum. Limited to 3 crowns per family per year. 3 Implants per beneficiary per annum. Conscious sedation and extensive dental treatment are limited to children up to the age of 16. Impactions (pre-authorisation required) . In hospital Extensive dental treatment for children under the age of 7 years and more than 3 teeth involved. Limited to once per 365 days per beneficiary. Removal of symptomatic impacted teeth covered only as a Day case at a Day Hospital. ",
    "crucialRules": "8101 Full mouth examination, Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. Code 8107, 8112 and 8121 cannot be charged more than 7 times (per year). 8159,8155,8161 Once per 6 months per member from date of service. 8163 Maximum of 4 can be charged per member per annum. RCT Only covered on permanent teeth. \u2022 Excluding wisdom teeth (3rd molars). 1 Set of plastic dentures, full or partial (an upper and a lower) per beneficiary in a 4 year period for members.",
    "notes": "8115 Extra-oral radiograph \u2013 panoramic 1 Scan per member every 2 years. 8116 Extra-oral radiograph \u2013 cephalometric Only applicable on Orthodontics. 8220 Suture material Applicable with surgical procedures. 8935 Treatment of septic socket Once per tooth number per lifetime. Not on the same day as an extraction. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "OLD ASCEND AND GOLD ASCEND EDO",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and xrays received \uf0b7 Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered \uf0b7 Replacement of noncarious amalgam with resin will not be cover. \uf0b7 Posterior teeth restorations cannot be performed across the midline. No Specialised dentistry. Metal, porcelain or Resin inlays except where such inlays form part of a bridge. Root canal treatment and laboratory fabricated crowns on primary teeth. ",
    "preAuth": "Maximum of 1 X 8201 per quadrant per visit. \u2022 Maximum of 1 X 8201 per quadrant per visit. \u2022 More than 4 extractions per visit require preauthorisation. 8937 Surgical removal of tooth More than 1 removal per visit requires authorisation. Pre-authorisation and xrays required for: \u2022 More than 4 restorations per year. \u2022 More than 3 restorations per visit. \u2022 Any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered). \u2022 Multiple restorations on an anterior tooth on the same service date only per authorisation and x rays received. \u2022 Lower jaw posterior fillings involving anaesthetic cannot be performed across the midline. 1Restoration code per tooth number in a 9 month period. 1 Set of plastic dentures, full or partial (an upper and a lower) per beneficiary in a 4-year period for members. Conscious sedation and extensive dental treatment are limited to children up to the age of 16. In Hospital Extensive dental treatment for children 7 years of age and 3 or more teeth involved. Limited to once per 365 days per beneficiary. Removal of symptomatic impacted wisdom teeth, covered only as a Day Case. ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101, 8102, 8104. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Maximum of 4 can be charged per member per annum. Code 8107, 8112 and 8121 cannot be charged more than 7 times (per year). Root Canal Only covered on permanent teeth. ",
    "notes": "Where root canal treatment has failed; benefits are allocated for a re-root canal treatment on the tooth. In the event that the re-root canal treatment fails, benefits will be available for an apicectomy. \uf0b7 Crowns and four surface restorations on third molars require pre-authorisation with supporting radiograph. Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "STANDARDBANK",
    "plan": "BEWELL 103 PLAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "NO",
    "nonNetworkedPayment": "NO",
    "balancedBilling": "NO",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": " . Motivation and records required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) Benefit for fillings is granted once per tooth in 9 months",
    "crucialRules": "8101 Full mouth examination, charting & treatment planning General Dental Practitioner or Dental Therapist Two consultations per dependant per year (once every 6 months). 8107 and/or 8112 Maximum of 2 X-rays films per visit per dependant . 8155 and/or 8159 Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159). 8161 Fluoride treatment Two treatments per year for dependants younger than 12 years of age (once every 6 months) . 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots . 8132 Pulpectomy (pain relief) Not covered on primary teeth",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Suremed",
    "plan": "Explorer and Shuttle Options",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "no",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered",
    "preAuth": "8201 & 8202 Combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon preauthorisation. Posterior amalgam and resin fillings are remunerated at the same tariff. 1 set per family every 24 months. A co-payment is payable on all dentures, equal to 20% of the total fees charged by the dentist and laboratory",
    "crucialRules": "8101 One per beneficiary per annum. 8104 Not within six weeks of 8101 or 8104. 8107/8112 Maximum four per annum combined, unless otherwise authorised. 8155 & 8159 Once per annum per member (either or 8155) 8161 Maximum of one per child per year for patients younger than 12 years. 8163 Maximum of two per child per year for patients younger than 12 years. 8935 Septic socket treatment Once per tooth. 8259 Rebase of denture (laboratory) Rebase complete or partial denture (once a calendar year per member). 8261 Remodel of denture Rebase complete or partial denture (once a calendar year per member. Reline complete or partial denture (once a calendar year per member) Repair denture Twice per calendar year per member",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Suremed",
    "plan": "Challenger",
    "administrator": " Momentum",
    "contactInfo": {
      "email": [
        "info@suremedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "086 008 0888"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "Yes",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and x-rays received . Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered \uf0b7 Replacement of noncarious amalgam restored tooth with a resin will not be covered. Posterior teeth restorations cannot be performed across the midline. Inlay/onlay or \u00be crowns not covered . Crowns on nonfunctional 3rd molars are not covered.",
    "preAuth": "Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial metal frames (an upper and a lower) per beneficiary in a 5-year period.",
    "crucialRules": "Emergency pain and sepsis treatment and extractions only, one per beneficiary per year. No Panorex.X-rays: Extraoral One per beneficiary in a 3-year period. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Suremed",
    "plan": "Navigator",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "info@suremedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 08 08 88"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered \uf0b7 Replacement of noncarious amalgam with resin will not be cover. Crowns on nonfunctional 3rd molars are not coveredered",
    "preAuth": "Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Partial Chrome Cobalt Frame Dentures Pre-authorisation required Two partial metal frames (an upper and a lower) per beneficiary in a 5-year period.",
    "crucialRules": "Emergency pain and sepsis treatment and extractions only, one per beneficiary per year. No Panorex.X-rays: Extraoral One per beneficiary in a 3-year period. 8159,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8163 Fissure sealant Limited to beneficiaries from age 5 and younger than 16 years of age . Root Canal Therapy and Extractions ",
    "notes": " Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Thebemed",
    "plan": "ENERGY ",
    "administrator": "DEnis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "no",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and Associated Laboratory Costs No benefit",
    "preAuth": "Benefit for fillings: Granted once per tooth in 720 days, Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Pre-authorisation required One partial metal frame (an upper or a lower) per beneficiary in a 5-year period . Pre-authorisation required One crown per beneficiary per year. Hospitalisation (General Anaesthetic) Pre-authorisation required General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment General anaesthetic benefit available for the removal of impacted teeth . Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment ",
    "crucialRules": "Two check-ups per beneficiary per year (once every 6 months). X-rays: Intraoral Benefit subject to managed care protocols. X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for scale and polish: Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 13 years of age. Root Canal Therapy and Extractions Benefit subject to managed care protocols.",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). \u2022 Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Thebemed",
    "plan": "UNIVERSAL PLAN & EDO",
    "administrator": "DEnis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex No specialised dentistry",
    "preAuth": "Maximum of four fillings per beneficiary per calendar year Benefit for fillings granted once per tooth in 720 days. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period .",
    "crucialRules": "8101 One per beneficiary per calendar year. 8107 & 8112 Four X-rays in total per beneficiary per calendar year . 8159 & 8155 One per beneficiary per calendar year (either 8159 or 8155). 8104 Benefit subject to managed care protocols . 8129 Benefit subject to managed care protocols . 8132 Benefit subject to managed care protocols. Root Canal Treatment All other codes: ONLY covered on permanent teeth . 8201 Extraction of tooth or exposed roots ",
    "notes": "Benefits payable on the Universal Plan and the Universal Plan EDO are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. If there is no DSP in the member\u2019s area, the member needs to contact DENIS prior to treatment. Patient written consent needed for procedures not covered. 2. Lower rate applies to non network provider ",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Thebemed",
    "plan": "Fantasy",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and Associated Laboratory Costs No benefit",
    "preAuth": "Maximum of four fillings per beneficiary per calendar year Benefit for fillings granted once per tooth in 720 days. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . 8941 Benefit subject to managed care protocols . . Partial Chrome Cobalt Frame Dentures Pre-authorisation required One partial frame (an upper or a lower) per beneficiary in a 5-year period. Crowns Pre-authorisation required One crown per family per year Benefit for crowns granted once per tooth in a 5-year period . Hospitalisation (General Anaesthetic) Pre-authorisation required PMB admission only . Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment ",
    "crucialRules": "8102 One per beneficiary per calendar year. 8107 & 8112 Four X-rays in total per beneficiary per calendar year . 8159 & 8155 One per beneficiary per calendar year (either 8159 or 8155). 8104 Benefit subject to managed care protocols . 8129 Benefit subject to managed care protocols . 8132 Benefit subject to managed care protocols. Root Canal Treatment All other codes: ONLY covered on permanent teeth . 8201 Extraction of tooth or exposed roots ",
    "notes": "Conservative dentistry benefits are payable from Risk, limited to the specified list of procedure codes and benefit limitations stipulated in the Conservative Dentistry table below. Specialised dentistry benefits are payable from the Member\u2019s Savings Account,",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Consumer Goods Medical Scheme (CGMS)",
    "plan": "Base Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment . 80% of cost payable by the scheme and includes professional and laboratory fees. 20% member liability",
    "crucialRules": "Limited to 1 consultation per beneficiary. One set of dentures every 24 months per beneficiary. A 20% co- payment applies. Restorations /Composite Fillings Preauthorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Extractions re-authorisation required for 4 or more extractions per annum per beneficiary. Teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of paymen",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Transmed",
    "plan": "TRANSMED SELECT",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Extraoral X-ray \u2013 panoramic 8115 No benefit - Covered if provided with impacted wisdom tooth removal authorisation application; an additional 8115 can be allowed for Orthodontics. Implants and Associated Laboratory Costs No benefit ",
    "preAuth": "One restoration code per tooth number in 720 days Multiple fillings on anterior teeth on the same service date only per treatment plan and motivation, including X-rays, received. Pre-authorisation and X-rays required for any 3 or 4 surface fillings on wisdom teeth (non functional wisdom teeth not covered) RCT One per beneficiary in 365 days. Plastic Dentures Pre-authorisation required All codes (8231 \u2013 8273) are subject to the specialised dentistry limit of R5 676 per family per year. One crown per family per 2 years for beneficiaries 16 years and older Pre-authorisation required. Surgical removal of a tooth 8937 No pre-authorisation required; only covered out of hospital . Surgical removal of impacted tooth 8941 in Hospital *Pre-authorisation is required , ONLY for the surgical removal of impacted teeth . Deep Sedation in the Dental Rooms Pre-authorisation required. General Anaesthetic benefits are available for children under the age of 6 years for extensive dental treatment. Limited to 1 admission per beneficiary per 24 months. General Anaesthetic benefits are available for the removal of impacted teeth.",
    "crucialRules": "8101 Two annual consultations per beneficiary per year (once in 6 months, i.e. 180 days apart from previous 8101). 8104 Not within 4 weeks of an 8101, 8102, 8104 . 8107 and/or 8112 cannot be charged more than 7 times per year in total . Two annual treatments per beneficiary per year in total; once in 6 months (i.e. 180 days apart) for codes 8155 and/or 8159 . Two annual treatments per beneficiary per year (once in 6 months, i.e. 180 days apart from previous code 8161 or 8162) . 8167 Two annual treatments per beneficiary per year (once in 6 months, i.e. 180 days apart from previous 8167) .8201 A maximum of 8 per quadrant per permanent dentition and 5 per primary dentition . Emergency Pulp removal for the relief of acute pain prior to root canal therapy 8132 . 8163 Maximum of 8 (2 per quadrant) can be charged for beneficiaries younger than 16 years of age.",
    "notes": "8269 Repair of denture . 8273 Impression to repair denture , Rebase complete or partial denture, Remodel complete or partial denture, Reline, Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after new denture) 365 days per member.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Transmed",
    "plan": "GUARDIAN",
    "administrator": "DEnis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " Implants No benefit. Orthodontics No benefit. Metal base to full dentures, including the laboratory costs. Provisional crowns and associated laboratory costs \u2022 Emergency crowns and associated laboratory costs. ",
    "preAuth": "One restoration code per tooth number in 720 days Multiple fillings on anterior teeth on the same service date only per treatment plan and motivation, including X-rays, received. Pre-authorisation and X-rays required for any 3 or 4 surface fillings on wisdom teeth (non-functional wisdom teeth not covered). Pre-authorisation required to All codes (8231 \u2013 8273) are subject to a denture limit of R1 239 per family. Once depleted, the balance is payable from the available Specialised dentistry limit of R4 893 per family per year. Partial Chrome Cobalt Frame Dentures Pre-authorisation required 8281 One frame in 5 years per beneficiary 21 years and older One Crown per family per 2 years for beneficiaries 16 years and older . Deep Sedation in Dental Rooms Pre-authorisation required. Hospitalisation (General Anaesthetic) Subject to pre-authorisation . ",
    "crucialRules": "8102 Two annual consultations per beneficiary per year (once in 6 months, i.e. 180 days apart from previous 8101). 8104 Not within 4 weeks of an 8101, 8102, 8104 . 8107 and/or 8112 cannot be charged more than 7 times per year in total . Two annual treatments per beneficiary per year in total; once in 6 months (i.e. 180 days apart) for codes 8155 and/or 8159 . Two annual treatments per beneficiary per year (once in 6 months, i.e. 180 days apart from previous code 8161 or 8162) . 8167 Two annual treatments per beneficiary per year (once in 6 months, i.e. 180 days apart from previous 8167) .8201 A maximum of 8 per quadrant per permanent dentition and 5 per primary dentition . Emergency Pulp removal for the relief of acute pain prior to root canal therapy 8132 . 8163 Maximum of 8 (2 per quadrant) can be charged for beneficiaries younger than 16 years of age. Obturation\u2013anterior & premolars",
    "notes": "8269 Repair of denture . 8273 Impression to repair denture , Rebase complete or partial denture, Remodel complete or partial denture, Reline, Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after new denture) 365 days per member.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Transmed Medical Fund",
    "plan": "Link Plan",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment . 80% of cost payable by the scheme and includes professional and laboratory fees. 20% member liability",
    "crucialRules": "Limited to 1 consultation per beneficiary. One set of dentures every 24 months per beneficiary. A 20% co- payment applies. Restorations /Composite Fillings Preauthorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Extractions re-authorisation required for 4 or more extractions per annum per beneficiary. Teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "TymeHealth",
    "plan": "CONNECT, PREMIER & ELITE",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital",
    "preAuth": "Motivation and records required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) Benefit for fillings is granted once per tooth in 9 months",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. 8104 consultation for a specific problem, Not within 4 weeks of an 8101. Codes 8159 &,8155 Once per 6 months per member from date of service. 8161, Once per 6 months per member from date of service. 8107 and/or 8112 Maximum of 2 X-rays films per visit per dependant . 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots ",
    "notes": "Benefits payable on the MediClub iConnect, iPremier and iElite Plans are subject to the use of a Network Service Provider on the DENIS Dental Network. There will be no benefit for out-of-network visits and treatment.",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Umvuzo",
    "plan": "EXTREME",
    "administrator": "Umvuzo",
    "contactInfo": {
      "email": [
        "0866700242 info@umvuzohealth.co.za"
      ],
      "fax": [],
      "phone": [
        "060 070 2095"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. gold inlays in dentures. Orthodontic treatment, is excluded from benefits. No dental procedure under general anaesthesia or conscious sedation will be funded. Dental benefits are limited to procedures as per agreed list of codes in the rooms contracted preferred providers as set out above. ",
    "preAuth": "SPECIALISED DENTISTRY; R10 200 per family per year\u2022 Orthodontic, Periodontic and Prosthodontic Treatment\u2022 Metal Base Dentures\u2022 Ceramic/Laminated Inlays\u2022 Gold Inlays",
    "crucialRules": "only Emergency root canal.",
    "notes": "This Option allows members to have more secondary benefits, makes use of the Universal Network",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Umvuzo",
    "plan": "ACTIVATOR",
    "administrator": "Umvuzo",
    "contactInfo": {
      "email": [
        "0866700242 info@umvuzohealth.co.za"
      ],
      "fax": [],
      "phone": [
        "060 070 2095"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. gold inlays in dentures. Orthodontic treatment, is excluded from benefits. No dental procedure under general anaesthesia or conscious sedation will be funded. Dental benefits are limited to procedures as per agreed list of codes in the rooms contracted preferred providers as set out above. ",
    "preAuth": " Fillings: \u2022 Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years.",
    "crucialRules": "only Emergency root canal. \u00bbConsultations \u00bbCleaning, Preventative & Fluoride Treatment, \u00bbScaling & Polishing \u00bbFillings \u00bbWisdom Teeth Extraction \u00bbDentures \u00bbCrowns \u00bbBridges",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Umvuzo",
    "plan": "SUPREME",
    "administrator": "Umvuzo",
    "contactInfo": {
      "email": [
        "0866700242 info@umvuzohealth.co.za"
      ],
      "fax": [],
      "phone": [
        "060 070 2095"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. gold inlays in dentures. Orthodontic treatment, is excluded from benefits. No dental procedure under general anaesthesia or conscious sedation will be funded. Dental benefits are limited to procedures as per agreed list of codes in the rooms contracted preferred providers as set out above. ",
    "preAuth": " Fillings: \u2022 Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. Plastic dentures \u2022 1 set of plastic dentures (an upper and a lower) per beneficiary per three years. \u2022 Partia",
    "crucialRules": "only Emergency root canal. \u00bbConsultations \u00bbCleaning, Preventative & Fluoride Treatment, \u00bbScaling & Polishing \u00bbFillings \u00bbWisdom Teeth Extraction \u00bbDentures \u00bbCrowns \u00bbBridges",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Umvuzo Health",
    "plan": "Standard Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. ",
    "crucialRules": " Root Canal Treatment Emergency only Dentures No benefits.",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Umvuzo Health",
    "plan": "Ultra Affordable Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. gold inlays in dentures. Orthodontic treatment, is excluded from benefits. No dental procedure under general anaesthesia or conscious sedation will be funded. ",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiar. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Elective maxillo -facial and oral surgery;",
    "crucialRules": "Essential dentistry which includes the following: \u00bbR3 700 per beneficiary per year \u00bbBenefit includes: Extractions, Preventative & Fluoride Treatment, Cleaning, Scaling & Polishing, Dental Fillings, Oral X-rays, Crowns, Bridges \u00bb Emergency Root Canal \u00bbWisdom Teeth Extraction (in the dentists\u2019 rooms).",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Umvuzo Health",
    "plan": "Ultra Affordable Value Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. gold inlays in dentures. Orthodontic treatment, is excluded from benefits. No dental procedure under general anaesthesia or conscious sedation will be funded. ",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiar. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Elective maxillo -facial and oral surgery;",
    "crucialRules": "Essential dentistry which includes the following: \u00bbR3 700 per beneficiary per year \u00bbBenefit includes: Extractions, Preventative & Fluoride Treatment, Cleaning, Scaling & Polishing, Dental Fillings, Oral X-rays, Crowns, Bridges \u00bb Emergency Root Canal \u00bbWisdom Teeth Extraction (in the dentists\u2019 rooms).",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Universal Workplace Health Plan (WHP)",
    "plan": "truCARE truHEALTH truWELLNESS",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment ",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "Witbank Coalfields Medical Aid Scheme",
    "plan": "Ntsika Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment . 80% of cost payable by the scheme and includes professional and laboratory fees. 20% member liability",
    "crucialRules": "Limited to 1 consultation per beneficiary. One set of dentures every 24 months per beneficiary. A 20% co- payment applies. Restorations /Composite Fillings Preauthorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Extractions re-authorisation required for 4 or more extractions per annum per beneficiary. Teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  },
  {
    "scheme": "COMPCARE",
    "plan": "Umbono Option & Umbono ED* Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "No",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings preauthorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Wisdom teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment . 80% of cost payable by the scheme and includes professional and laboratory fees. 20% member liability",
    "crucialRules": "Limited to 1 consultation per beneficiary. One set of dentures every 24 months per beneficiary. A 20% co- payment applies. Restorations /Composite Fillings Preauthorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Extractions re-authorisation required for 4 or more extractions per annum per beneficiary. Teeth extractions to be done in dentist\u2019s rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2 per beneficiary per annum. Root Canal Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-25"
  }
]