import React, {useEffect, useState} from "react";
import {useLocation, useHistory} from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TermsAndConditions from "../components/TermsAndConditions";

import Header from "../components/Header";
import MedicalAid from "../components/MedicalAid";
import {medicalAidResults} from "../consts/MedicalAids";
import FilterSelect from "../components/FilterSelect";

const schemePlans = {}
const medicalSchemes = [...new Set(medicalAidResults.map(medicalAid => {
    schemePlans[medicalAid?.scheme] = [medicalAid?.plan, ...(schemePlans[medicalAid?.scheme] || [])]
    return medicalAid?.scheme
}))]
const tariffCodes = [...medicalSchemes].sort()

const homeStyles = makeStyles(theme => ({
    'column-headers':{
        display: 'grid',
        columnGap: '2.5%',
        fontSize: '0.8em',
        alignItems: 'end',
        gridTemplateColumns: '1fr 1.25fr 1fr 1fr 1fr 1fr 1fr',
        margin: '1em auto',
        width: '90%',
        color: theme.palette.primary.light,
    },
    root: {
        padding: "1.5em 1.5em 0",
        fontSize: "1.2em",
        color: "#293754",
        background: '#FAFAFA',
        minHeight: 'calc(100vh - 4.5em)',
        display: 'grid',
        gridTemplateRows: '60px auto',

        '&.mobile': {
            padding: '1.5em',
            minHeight: 'calc(100vh - 3em)',
        }
    },
    resultContainer: {
        '&.desktop': {
            maxHeight: 'calc(100vh - 60px - 16px - 1em - 3em)',
            overflow: 'auto'
        },
        '&.mobile': {
            maxHeight: 'calc(100vh - 60px - 3em)',
            overflow: 'auto'
        },
    },
    center: {
        height: 'calc(90% - 4em)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    prompt: {
        color: theme.palette.primary.light,
        '&.desktop': {
            margin: '1.5em auto 0.5em 3.75em',
            fontSize: '1.35em',
        },
        '&.mobile': {
            marginTop: '1em',
            fontSize: '1.15em',
        }
    },
    'not-found': {
        color: theme.palette.primary.main,
        textAlign: 'center',
        '& div': {
            color: theme.palette.primary.light,
            fontSize: '0.8em',
            padding: '0.5em',
        },
        '&.desktop': {
            margin: '5% auto',
            transform: 'translateY(-50%)',
            fontSize: '1.35em',
        },
        '&.mobile': {
            marginTop: '50%',
            fontSize: '1.15em',
        }
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        height: '16px',
        alignSelf: 'end',
        fontSize: '.75em',
        background: '#FAFAFA',
        padding: '1em 0 3em',
        color: theme.palette.primary.light,
        '& a': {
            textDecoration: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        '&.mobile': {
            margin: 'auto 0 1em',
            background: 'white',
            alignSelf: 'center',
            '& a': {
                display: 'block',
                textAlign: 'center'
            }
        },
        '& span': {
            margin: '0 0.75em'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    linkButton: {
        color: 'inherit',
        fontWeight: 'bolder',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline',
        margin: 0,
        padding: 0,
        textDecoration: 'underline',
    },
    container: {
        '& .heading': {
            fontWeight: 'bold',
            fontSize: '1.25em',
            margin: '0 0 0.5em',
            textAlign: 'center'
        },        
        '& .subHeading': {
            margin: '0 0 1em',
            textAlign: 'center'
        },
        '& .contact-container': {
            paddingBottom: '1em',

            '& .label': {
                fontSize:'0.9em', 
                fontWeight:'bold', 
                paddingBottom: '0.25em', 
                alignItems: 'center', 
                columnGap: '0.5em', 
                display: 'flex'
            },
            '& .description': {
                fontSize:'0.9em',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.25em',
    
                '& svg': {
                    fontSize: '1em',
                    padding: '0.25em',
                    opacity: '0.5',
                    color: '#215763'
                }
            }
        }
    }
}));

const escapeSpecialCharsForRegex = (value = '') => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const filterResults = (scheme='', plan='') => {
    const groupedResults = {}
    let filteredResults = []

    scheme = escapeSpecialCharsForRegex(scheme || '');
    plan = escapeSpecialCharsForRegex(plan || '');

    if (scheme) filteredResults = medicalAidResults.filter((result) => result.scheme.match(new RegExp(scheme, 'i')))
    if (plan) filteredResults = (filteredResults || medicalAidResults).filter((result) => result.plan.match(new RegExp(plan, 'i')))

    const other = filteredResults || medicalAidResults
    other.forEach(result => groupedResults[result.scheme] = [...(groupedResults[result.scheme] || []), result])

    console.log(scheme, plan, filteredResults)
    return groupedResults
}

const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 1250px)").matches

const Results = ({input}) => {
    const classes = homeStyles();
    const location = useLocation();
    const history = useHistory();

    const [medicalScheme, setMedicalScheme] = useState(input || location.input);
    const [schemePlan, setSchemePlan] = useState(null);
    const [TandCOpen, setTandCOpen] = useState(false);

    const [platform, setPlatform] = useState("desktop");

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [medicalAidResults, setMedicalAidResults] = useState([]);

    useEffect(() => {
        setPlatform(isMobile() ? 'mobile' : 'desktop') 
        if (![1,2].includes(medicalScheme?.length)) {
            setMedicalAidResults(filterResults(medicalScheme, schemePlan)) 
        }
    }, [medicalScheme, schemePlan])

    useEffect(() => {
        if (platform === 'desktop') {
            if (![1,2].includes(medicalScheme?.length)) {
                setMedicalAidResults(filterResults(medicalScheme, schemePlan)) 
            }
        }
    }, [platform, medicalScheme, schemePlan])
    
    useEffect(() => {
        if (platform === 'desktop') {
            setSchemePlan(null)
            if (![1,2].includes(medicalScheme?.length)) {
                setMedicalAidResults(filterResults(medicalScheme, null)) 
            }
        }
    }, [platform, medicalScheme])


    useEffect(() => {
        const handleResize = () => setPlatform(isMobile() ? 'mobile' : 'desktop')        
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    });


    return (
        <>
        <div className={platform === 'desktop' ? classes.root : `${classes.root} mobile`}>
            <Modal
                open={TandCOpen}
                onClose={() => setTandCOpen(false)}
                className={classes.modal}
            >
                <TermsAndConditions platform={platform} onClick={() => setTandCOpen(false)} />
            </Modal>
            
            {/* PAGE HEADER WITH INPUTS */}
            <Header 
                platform={platform}
                menuHandler={setFiltersOpen}
                tariffCodes={tariffCodes} 
                medicalSchemes={medicalSchemes} 
                medicalScheme={medicalScheme} 
                schemePlans={schemePlans}
                schemePlan={schemePlan} 
                setSchemePlan={setSchemePlan} 
                setMedicalScheme={setMedicalScheme}
                setSearchTerm={() => {
                    if (![1,2].includes(medicalScheme?.length)) {
                        setMedicalAidResults(filterResults(medicalScheme, schemePlan)) 
                    }
                }}
            />
            
            {/* GROUPED SEARCH RESULTS */}

            <div className={`${classes.resultContainer} ${platform}`}>
                {Object.keys(medicalAidResults).length === 0 && <div className={[classes['not-found'], platform].join(' ')}><b>No results found</b><div>Please use the filters to find the scheme or option you are looking for</div></div>}
                {Object.keys(medicalAidResults)?.map((scheme) => { return <>
                        <div className={[classes.prompt, platform].join(' ')}>{scheme}</div>
                        {platform === 'desktop' && <div className={classes['column-headers']}>
                            <div className='header'>Scheme</div>
                            <div className='header'>Option</div>
                            <div className='header'>Network Applying?</div>
                            <div className='header'>Payment for non-network provider?</div>
                            <div className='header'>Balanced billing allowed?</div>
                            <div className='header'>Last updated</div>
                        </div>}
                        {medicalAidResults[scheme].map(result => <MedicalAid result={result} platform={platform} modalHandler={(detail) => {}} />)}                        
                    </>}
                )}
            </div>

            {/* MOBILE FILTER MENU */}

            <Drawer
                anchor={'right'}
                open={filtersOpen}
                onClose={() => setFiltersOpen(false)}
                sx={{
                    '& .MuiPaper-root': {
                        width: 'calc(80% - 2em)',
                        padding: '1em',
                        borderRadius: '0px !important',
                    }
                }}
            >   
                <h2 style={{margin: '0.5em auto'}}>Advanced search</h2>
                <h5 style={{fontWeight: 'normal', margin: '0 auto 1em'}}>Apply filters to focus your search</h5>
                <FilterSelect value={medicalScheme} setValue={setMedicalScheme} options={medicalSchemes} placeholder="Select a scheme" label="Medical scheme" />
                <FilterSelect value={schemePlan} setValue={setSchemePlan} options={schemePlans?.[medicalScheme]} placeholder="Select an option" label="Scheme option" />
                <br/>
                <Button variant="contained" sx={{background: '#e4af44',borderRadius: '2em', fontWeight: 'bold'}} onClick={() => {
                    setMedicalScheme('');
                    history.push('/')
                }}>Clear filters</Button>
                <Button variant="contained" sx={{marginTop: '1em', background: '#138084',borderRadius: '2em', fontWeight: 'bold'}} onClick={() => {setFiltersOpen(false); if (![1,2].includes(medicalScheme?.length)) {
                    setMedicalAidResults(filterResults(medicalScheme, schemePlan))
                }}}>Search</Button>
                {platform === 'mobile' && <div className={`${classes.footer} mobile`}>
                    <span>By using this service you agree to our <button className={classes.linkButton} onClick={() => setTandCOpen(true)}>Terms and Conditions</button></span>
                </div>}
            </Drawer>
        </div>
        {platform === 'desktop' && <div className={classes.footer}>
            <span>By using this service you agree to our <button className={classes.linkButton} onClick={() => setTandCOpen(true)}>Terms and Conditions</button></span>
        </div>}
        </>
    )
}

export default Results;