import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import TitleSearch from '../components/TitleSearch';
import { medicalAidResults } from '../consts/MedicalAids';
import Logo from '../components/Logo';
import Modal from '@mui/material/Modal';
import TermsAndConditions from '../components/TermsAndConditions';

const schemePlans = {};
const medicalSchemes = [
    ...new Set(
        medicalAidResults.map((medicalAid) => {
            schemePlans[medicalAid?.scheme] = [
                medicalAid?.plan,
                ...(schemePlans[medicalAid?.scheme] || []),
            ];
            return medicalAid?.scheme;
        })
    ),
];
const tariffCodes = [...medicalSchemes].sort();

const homeStyles = makeStyles((theme) => ({
    root: {
        padding: '1.5em 1.5em 0',
        fontSize: '1.2em',
        color: '#138084',
        background: '#FAFAFA',
        minHeight: 'calc(100vh - 4.5em)',
        display: 'grid',
        gridTemplateRows: '60px auto',

        '&.mobile': {
            padding: '1.5em',
            minHeight: 'calc(100vh - 3em)',
        },
    },
    center: {
        height: 'calc(90% - 4em)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        height: '16px',
        alignSelf: 'end',
        fontSize: '.75em',
        background: '#FAFAFA',
        padding: '1em 0 2em',
        color: theme.palette.primary.light,
        '& a': {
            textDecoration: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        '&.mobile': {
            margin: 'auto 0 1em',
            background: 'white',
            alignSelf: 'center',
            '& a': {
                display: 'block',
                textAlign: 'center'
            }
        },
        '& span': {
            margin: '0 0.75em',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'grid',
        height: '60px',
        width: '100%',
        columnGap: '20px',
        alignItems: 'center',
        '&.desktop': {
            gridTemplateColumns: 'min(50%, 250px) 300px 300px auto',
        },
        '&.mobile': {
            gridTemplateColumns: 'minmax(auto, 350px) auto',
        },
    },
    menuButton: {
        gridColumn: '2 / span 1',
        justifySelf: 'end',
        alignSelf: 'center',
        fontSize: '1.75em !important',
    },
    linkButton: {
        color: 'inherit',
        fontWeight: 'bolder',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline',
        margin: 0,
        padding: 0,
        textDecoration: 'underline',
    }
}));

const Header = ({ platform, classes }) => {
    if (platform === 'desktop') {
        return (
            <div className={[classes.container, 'desktop'].join(' ')}>
                <Logo />
            </div>
        );
    } else {
        return <div />
    }
};

const isMobile = () => window.matchMedia && window.matchMedia('(max-width: 1250px)').matches;

const Home = ({ setInput }) => {
    const history = useHistory();
    const classes = homeStyles();

    const [searchTerm, setSearchTerm] = useState(null);
    const [platform, setPlatform] = useState('mobile');
    const [TandCOpen, setTandCOpen] = useState(false);


    useEffect(() => {
        setPlatform(isMobile() ? 'mobile' : 'desktop');
    }, []);

    useEffect(() => {
        const handleResize = () => setPlatform(isMobile() ? 'mobile' : 'desktop');
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <>
            <div className={platform === 'desktop' ? classes.root : `${classes.root} mobile`}>
                <Modal
                    open={TandCOpen}
                    onClose={() => setTandCOpen(false)}
                    className={classes.modal}
                >
                    <TermsAndConditions platform={platform} onClick={() => setTandCOpen(false)} />
                </Modal>

                {/* PAGE HEADER WITH INPUTS */}
                <Header platform={platform} classes={classes} />

                {/* HOME SPLASH SEARCH INTERFACE */}
                <div className={classes.center}>
                    <TitleSearch
                        platform={platform}
                        tariffCode={searchTerm}
                        tariffCodes={tariffCodes}
                        setTariffCode={(value) => {
                            setSearchTerm(value);
                        }}
                        setSearchTerm={(value) => {
                            setInput(value);
                            history.push('/results', { input: value });
                        }}
                    />
                </div>
            </div>
            {platform === 'desktop' && (
                <div className={classes.footer}>
                    <span>By using this service you agree to our <button className={classes.linkButton} onClick={() => setTandCOpen(true)}>Terms and Conditions</button></span>
                </div>
            )}
        </>
    );
};

export default Home;
